import Vue from "vue";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import elementJSONToPropsHelper from "../mixins/elementJSONToPropsHelper";
import permissionHelper from "../mixins/permissionHelper";
import router from "../router";

/*
NOTE! axios delete request can not have a body

post/put/patch requests with body are build like:

axios.post(url,
  {
    "data" : { //data field for API
      ...
    }
  },
  {
    headers: {
      Authorization: "Bearer " + this.getToken
    }
  }
)

-> body in data parameter, headers in config parameter
-> body and headers as separate parameters without data key


delete with body has to be build like:

axios.delete(url,
  {
    data: {
      "data" : { //data field for API
        ...
      }
    },
    headers: {
      Authorization: "Bearer " + this.getToken
    }
  }
)

-> data & headers both in config parameter
-> together in 1 {} !
-> extra data key

*/

export default {
  mixins: [
    elementJSONToPropsHelper,
    permissionHelper,
  ],

  data() {
    return {
      elementsData: [],
      errorCodes: {
        2: Vue.i18n.translate("httpHelperTranslation.errorCode2"),
        3: Vue.i18n.translate("httpHelperTranslation.errorCode3"),
        4: Vue.i18n.translate("httpHelperTranslation.errorCode4"),
        5: Vue.i18n.translate("httpHelperTranslation.errorCode5"),
        6: Vue.i18n.translate("httpHelperTranslation.errorCode6"),
        7: Vue.i18n.translate("httpHelperTranslation.errorCode7"),
        10: Vue.i18n.translate("httpHelperTranslation.errorCode10"),
        11: Vue.i18n.translate("httpHelperTranslation.errorCode11"),
        12: Vue.i18n.translate("httpHelperTranslation.errorCode12"),
        13: Vue.i18n.translate("httpHelperTranslation.errorCode13"),
        14: Vue.i18n.translate("httpHelperTranslation.errorCode14"),
        20: Vue.i18n.translate("httpHelperTranslation.errorCode20"),
        21: Vue.i18n.translate("httpHelperTranslation.errorCode21"),
        22: Vue.i18n.translate("httpHelperTranslation.errorCode22"),
        23: Vue.i18n.translate("httpHelperTranslation.errorCode23"),
        24: Vue.i18n.translate("httpHelperTranslation.errorCode24"),
        25: Vue.i18n.translate("httpHelperTranslation.errorCode25"),
        30: Vue.i18n.translate("httpHelperTranslation.errorCode30"),
        31: Vue.i18n.translate("httpHelperTranslation.errorCode31"),
        32: Vue.i18n.translate("httpHelperTranslation.errorCode32"),
        40: Vue.i18n.translate("httpHelperTranslation.errorCode40"),
        41: Vue.i18n.translate("httpHelperTranslation.errorCode41"),
        42: Vue.i18n.translate("httpHelperTranslation.errorCode42"),
        82: Vue.i18n.translate("httpHelperTranslation.errorCode82"),
        83: Vue.i18n.translate("httpHelperTranslation.errorCode83"),
        84: Vue.i18n.translate("httpHelperTranslation.errorCode84"),
        85: Vue.i18n.translate("httpHelperTranslation.errorCode85"),
      },
      httpErrorCodes: {
        500: Vue.i18n.translate("httpHelperTranslation.httpErrorCode500"),
        503: Vue.i18n.translate("httpHelperTranslation.httpErrorCode503"),
        504: Vue.i18n.translate("httpHelperTranslation.httpErrorCode504"),
      },
    };
  },

  computed: {
    ...mapGetters([
      "getToken",
      "getUserId",
      "getAppLanguage",
      "getIsEditable",
    ]),
  },

  methods: {
    ...mapMutations([
      "CHANGE_TOKEN",
      "SET_LESSONELEMENTSARR",
      "SET_NOTIFICATIONTEXT",
      "SET_ROLES",
      "SET_AVAILABLELOCALES",
      "SET_USERID",
      "SET_MYROLEFORSTUDY",
      "SET_ROLEIDS",
    ]),

    handleErrors: async function(error, func, name) {
      let errorText = "";
      if (error.response === undefined) {
        errorText = error.message;
      } else if (error.response.status.toString().startsWith("4")) {
        if (error.response.status === 401 && error.response.data.errors[0].code != "28") {
          try {
            await this.refreshTokenRequest()
            return func();
          } catch (error2) {
            if (name === "logout") {
              this.CHANGE_TOKEN("");
            }
            if (error2.response === undefined) {
              errorText = error2.message;
            } else {
              errorText = this.errorCodes[error2.response.data.errors[0].code];
            }
          }
        } else if (error.response.status === 401 && error.response.data.errors[0].code === "28") {
          errorText = Vue.i18n.translate("httpHelperTranslation.wrongPassword");
        } else if (error.response.status.toString() == "404" && name === "diaryDetails") {
          errorText = Vue.i18n.translate("diaryTranslation.diaryNotComplete");
        } else if (
          (
            error.response.status === 403 ||
            error.response.status === 404 ||
            error.response.data.errors[0].code === "2" ||
            error.response.data.errors[0].code === "10"
          ) &&
          (name === "studyDetails" || name === "diaryDetails")
        ) {
          errorText = Vue.i18n.translate("httpHelperTranslation.errorCode2");
          this.SET_MYROLEFORSTUDY("None");
          router.replace("/errorPage");
        } else if(name === "logout") {
          this.CHANGE_TOKEN("");
        } else if(name === "createAccount") {
          if (error.response.status === 422) {
            errorText = Vue.i18n.translate("httpHelperTranslation.registerErrorEmailInvalid");
          } else if (error.response.status === 403 || error.response.status === 409) {
            if (JSON.stringify(error.response).includes("username")) {
              errorText = Vue.i18n.translate("httpHelperTranslation.usernameAlreadyExists");
            } else {
              errorText = Vue.i18n.translate("httpHelperTranslation.registerErrorEmailDuplicate");
            }
          } else {
            errorText = this.errorCodes[error.response.data.errors[0].code];
          }
        } else if (name === "submitFile") {
          if (error.response.status === 422) {
            errorText = Vue.i18n.translate("elementsMediaTranslation.fileNotSupported");
          } else {
            errorText = this.errorCodes[error.response.data.errors[0].code];
          }
        } else if (name === "profile" && error.response.status === 422) {
          errorText = Vue.i18n.translate("httpHelperTranslation.usernameAlreadyExists");
        } else if (name === "password" && error.response.status === 422 && JSON.stringify(error.response.data.errors[0].meta).includes("data leak")) {
          errorText = Vue.i18n.translate("httpHelperTranslation.passwordDataLeak");
        } else if (name === "config" && error.response.status === 403 && error.response.data.errors[0].code === "1510") {
          errorText = Vue.i18n.translate("httpHelperTranslation.configDiaryNotIncluded");
        } else if (name === "addRoleToPatient") {
          if (error.response.status === 422) {
            errorText = Vue.i18n.translate("httpHelperTranslation.usernameAlreadyExists");
          }
        } else if (name === "saveElementTranslations") {
          if (error.response.status === 422) {
            errorText = Vue.i18n.translate("httpHelperTranslation.saveElementTranslationError");
          }
        } else if (name === "createElements") {
          if (error.response.status === 403 && error.response.data.errors[0].code === "1510") {
            errorText = Vue.i18n.translate("httpHelperTranslation.createElementsLockError");
          }
        } else if (name === "StructureLock") {
          if (error.response.status === 403 && error.response.data.errors[0].code === "2") {
            errorText = Vue.i18n.translate("httpHelperTranslation.changeStructureLockAccessError");
          }
        }
      } else {
        if (name === "logout") {
          this.CHANGE_TOKEN("");
        }
        if (this.httpErrorCodes[error.response.status]) {
          errorText = this.httpErrorCodes[error.response.status];
        } else {
          errorText = error.response.detail;
        }
      }

      if (errorText === undefined) {
        if (this.errorCodes[error.response.data.errors[0].code]) {
          errorText = this.errorCodes[error.response.data.errors[0].code];
        } else {
          errorText = error.response.data.errors[0].detail;
        }
      }

      if (errorText != "") {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("httpHelperTranslation.error") + errorText });
      } else {
        this.SET_NOTIFICATIONTEXT("");
      }
    },

    handleErrorsNotLoggedIn: function(error, name) {
      let errorText = "";
      if (typeof error.response === "undefined") {
        errorText = error.message;
      } else if (error.response.status.toString() === "422" && name === "resendVerification") {
        errorText = Vue.i18n.translate("httpHelperTranslation.registerErrorEmailInvalid");
      } else if (error.response.status.toString() === "422" && name === "resetPW") {
        errorText = Vue.i18n.translate("httpHelperTranslation.errorInvalidEmail");
      } else if (error.response.status.toString() === "429" && name !== "resetPW") {
        errorText = Vue.i18n.translate("httpHelperTranslation.tooManyLogins");
      } else if (error.response.status.toString().startsWith("4")) {
        if (this.errorCodes[error.response.data.errors[0].code]) {
          errorText = this.errorCodes[error.response.data.errors[0].code];
        } else {
          errorText = error.response.data.errors[0].detail;
        }
      } else if (this.httpErrorCodes[error.response.status]) {
        errorText = this.httpErrorCodes[error.response.status];
      } else {
        errorText = error.response.detail;
      }
      if (errorText !== "") {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("httpHelperTranslation.error") + errorText });
      } else {
        this.SET_NOTIFICATIONTEXT("");
      }
    },

    parseElements: function(elements, checkConditionPages) {
      let counter = 1;
      const newElements = [];
      for (const elem in elements) {
        if (elements[elem].position === counter) {
          counter++;
          newElements.push(elements[elem]);
        }
      }
      if (checkConditionPages) {
        const result = this.jsonToProperties(newElements, true);
        if (this.getIsEditable && result.removedOldConditionalPage > 0) {
          this.SET_NOTIFICATIONTEXT({
            type: "error",
            text: Vue.i18n.translate(
              "lessonTranslation.removedOldConditionalPage",
              { counter: result.removedOldConditionalPage, pages: result.pages },
            ),
          });
        }
        return result.elements;
      } else {
        return this.jsonToProperties(newElements, false);
      }
    },

    loginRequest: async function(email, password) {
      const url = this.$url + "/auth/login";
      const json = {
        data: {
          type: "users",
          attributes: { email, password },
        },
      };
      const config = { withCredentials: true };
      return axios.post(url, json, config);
    },

    refreshTokenRequest: async function() {
      return new Promise((resolve, reject) => {
        const url = this.$url + "/auth/refresh";
        const json = { data: { } };
        const config = {
          headers: { Authorization: "Bearer " + this.getToken },
          withCredentials: true,
        };
        axios.post(url, json, config)
          .then((response) => {
            this.CHANGE_TOKEN(response.data.data.attributes.token);
            resolve();
          })
          .catch((error) => {
            if (error.response && error.response.status.toString() === "400" || error.response.status.toString() === "401" || error.response.status.toString() === "409") {
              this.SET_NOTIFICATIONTEXT({
                type: "error",
                text: Vue.i18n.translate("httpHelperTranslation.error") + Vue.i18n.translate("httpHelperTranslation.errorLoginAgain"),
              });
              this.CHANGE_TOKEN("");
              if (this.$route.name !== "login") {
                router.push({ name: "login" });
              }
            } else {
              this.handleErrorsNotLoggedIn(error, "");
            }
            reject(error);
          });
      })
    },

    createAccountForUserRequest: async function(json) {
      const url = this.$url + "/admin/auth/assign/register";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    resendVerificationForUserRequest: async function(email) {
      const url = this.$url + "/auth/verify/resend";
      const json = {
        data: {
          type: "users",
          attributes: { email },
        },
      };
      return axios.post(url, json)
        .then(() => {
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("adminUsersTranslation.resendVerificationSuccess") });
        })
        .catch((error) => {
          this.handleErrorsNotLoggedIn(error, "resendVerification");
        });
    },

    updatePasswordRequest: async function(json) {
      const url = this.$url + "/my/profile/password";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config)
        .then(() => {
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("preferencesTranslation.changePWSuccess") });
        })
        .catch((error) => {
          this.handleErrors(error, () => { this.updatePasswordRequest(json) }, "password");
        });
    },

    requestLessonElements: async function(lessonId, copyElementsBool, returnErrors) {
      const url = `${this.$url}/questionnaires/${lessonId}/elements`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const elements = response.data.data.attributes.elements;
          //filter duplicates
          let counter = 1;
          const newElements = [];
          /*
          var filesToCopy = [];
          var translation, filename;
          */
          for (const elem in elements) {
            //for moving media including name
            /*
            if(elements[elem].elementtype === "elements/media"){
              for(translation in elements[elem].translations){
                if(elements[elem].translations[translation].uri.startsWith("https://api.esano-trainings.de/uploads/media/")){
                  filesToCopy.push(
                    {
                      "url": elements[elem].translations[translation].uri,
                      "original_name": elements[elem].translations[translation].filename
                    }
                  );
                  filename = elements[elem].translations[translation].uri.split("").reverse().join("");
                  filename = filename.substr(0, filename.indexOf('/'));
                  filename = filename.split("").reverse().join("");
                  console.log("https://api.esano-trainings.de/uploads/studies/312/" + filename.split(".")[0])
                  elements[elem].translations[translation].uri = "https://api.esano-trainings.de/uploads/studies/" + studyId + "/" + filename.split(".")[0]
                }
              }
            }else if(elements[elem].elementtype === "elements/texts"){
              for(translation in elements[elem].translations){
                var string = elements[elem].translations[translation].text;
                var regEx = new RegExp(/https:\/\/api\.esano-trainings\.de\/uploads\/media\/\d{10}\.[a-zA-Z0-9]*/      /*,"g");
                var urls = string.match(regEx);
                for(var url in urls){
                  filename = urls[url].split("").reverse().join("");
                  filename = filename.substr(0, filename.indexOf('/'));
                  filename = filename.split("").reverse().join("");
                  filename = filename.split(".")[0];
                  filesToCopy.push(
                    {
                      "url": urls[url],
                      "original_name": filename
                    }
                  );
                }
                if(urls != null){
                  var part1 = new RegExp(self.$urlUploads);
                  var part2 = /\/media\/([0-9]*)\.[a-zA-Z0-9]*/       /*;
                  regEx = new RegExp(part1.source + part2.source, "g");
                  string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");
                  console.log(string);
                  elements[elem].translations[translation].text = string;
                }
              }
            }*/
            if (elements[elem].position === counter) {
              counter++;
              newElements.push(elements[elem]);
            }
          }
          if (!copyElementsBool) {
            const result = this.jsonToProperties(newElements, returnErrors);
            if (returnErrors) {
              if (this.getIsEditable && result.removedOldConditionalPage > 0) {
                this.SET_NOTIFICATIONTEXT({
                  type: "error",
                  text: Vue.i18n.translate(
                    "lessonTranslation.removedOldConditionalPage",
                    { counter: result.removedOldConditionalPage, pages: result.pages },
                  ),
                });
              }
              return result.elements;
            } else {
              return result;
            }
          } else {
            return newElements;
          }
        }
      );
    },

    copyMediaInDifferentWorkgroupRequest: async function(sourceStudyId, targetStudyId, files) {
      const url = `${this.$url}/editor/studies/${sourceStudyId}/media/copy`;
      const jsonObject = {
        data: {
          type: "studies",
          attributes: {
            study_id: targetStudyId,
            files: files,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject,config);
    },

    updateElementsTranslations: async function(jsonObject) {
      const url = `${this.$url}/editor/elements/translations`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const elements = response.data.data.attributes.elements;
          return this.parseElements(elements, true);
        }
      );
    },

    createLessonElementsRequest: async function(lessonId, jsonObject) {
      const url = `${this.$url}/editor/questionnaires/${lessonId}/elements`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const elements = response.data.data.attributes.elements;
          return this.parseElements(elements, true);
        }
      );
    },

    //studyType workgroup/study/organisationgroup
    //collaborating studies: workgroup is usually used; for copy/activate into study -> study used
    //non collaborating studies always requested with type workgroup
    requestCollaboratingOrNonCollaboratingStudies: async function(page, filterTerm, studyType, collaborating, getUnknownPageWithId, oldStudyId) {
      let queryString;
      if (getUnknownPageWithId) {
        queryString = "&limit=0";
      } else if (typeof page === "undefined" || page === "" || page <= 0) {
        queryString = "&page=1";
      } else {
        queryString = "&page=" + page;
      }
      let url = this.$url + "/editor/studies";
      if (!collaborating) {
        url += "/noncollaborating?include=owners&type=workgroup";
      } else {
        url += "?include=roles,owners&type="+ studyType;
      }
      url += filterTerm + queryString;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config)
        .then((response) => {
          if (response.data.meta && response.data.meta.current_page > response.data.meta.last_page) {
            return this.requestCollaboratingOrNonCollaboratingStudies(
              response.data.meta.last_page,
              filterTerm,
              studyType,
              collaborating,
              getUnknownPageWithId,
              oldStudyId,
            );
          } else {
            if (collaborating) {
              const rolesList = this.getRolesList(response.data.included);
              this.SET_ROLEIDS(rolesList);
            }
            const studyListData = {
              list: response.data.data,
              currentPage: response.data.meta ? response.data.meta.current_page : 0,
              allPages: response.data.meta ? response.data.meta.last_page : 0,
              total: response.data.meta ? response.data.meta.total : 0,
            };
            if (getUnknownPageWithId) {
              const index = response.data.data.findIndex(elem => Number(elem.id) === oldStudyId);
              const currentPage = index !== -1 ? Math.ceil((index + 1) / this.$perPage) : 1;
              studyListData.currentPage = currentPage;
              studyListData.allPages = Math.ceil(studyListData.total / this.$perPage);
              const begin = this.$perPage * (currentPage - 1);
              const number = this.$perPage * currentPage;
              studyListData.list = response.data.data.slice(begin, number);
            }
            return studyListData;
          }
        });
    },

    //(non)collaborating interventions always requested with type workgroup
    requestCollaboratingOrNonCollaboratingInterventions: async function(page, filterTerm, collaborating, getUnknownPageWithId, oldInterventionId) {
      let queryString;
      if (getUnknownPageWithId) {
        queryString = "&limit=0";
      } else if (typeof page === "undefined" || page === "" || page <= 0) {
        queryString = "&page=1";
      } else {
        queryString = "&page=" + page;
      }
      let url = this.$url + "/editor/interventions";
      if (!collaborating) {
        url += "/noncollaborating";
      }
      url += "?include=roles,owners&typeOfParentStudy=workgroup" + filterTerm + queryString
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config)
        .then((response) => {
          if (response.data.meta && response.data.meta.current_page > response.data.meta.last_page) {
            return this.requestCollaboratingOrNonCollaboratingInterventions(
              response.data.meta.last_page,
              filterTerm,
              collaborating,
              getUnknownPageWithId,
              oldInterventionId,
            );
          } else {
            const rolesList = this.getRolesList(response.data.included);
            this.SET_ROLEIDS(rolesList);
            const interventionListData = {
              list: response.data.data,
              currentPage: response.data.meta ? response.data.meta.current_page : 0,
              allPages: response.data.meta ? response.data.meta.last_page : 0,
              total: response.data.meta ? response.data.meta.total : 0,
            };
            if (getUnknownPageWithId) {
              const index = response.data.data.findIndex(elem => Number(elem.id) === oldInterventionId);
              const currentPage = index !== -1 ? Math.ceil((index + 1) / this.$perPage) : 1;
              interventionListData.currentPage = currentPage;
              interventionListData.allPages = Math.ceil(interventionListData.total / this.$perPage);
              const begin = this.$perPage * (currentPage - 1);
              const number = this.$perPage * currentPage;
              interventionListData.list = response.data.data.slice(begin, number);
            }
            return interventionListData;
          }
        });
    },

    requestCollaboratingDiaries: async function(page, filterTerm, getAll) {
      let queryString;
      if (getAll) {
        queryString = "&limit=0";
      } else if (typeof page === "undefined" || page === "" || page <= 0) {
        queryString = "&page=1";
      } else {
        queryString = "&page=" + page;
      }
      const url = `${this.$url}/editor/diaries?include=roles&typeOfParentStudy=workgroup${filterTerm}${queryString}`;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config)
        .then((response) => {
          if (response.data.meta && response.data.meta.current_page > response.data.meta.last_page) {
            return this.requestCollaboratingDiaries(response.data.meta.last_page, filterTerm, getAll);
          } else {
            const includedRoles = [];
            for (const entry in response.data.data) {
              const relationships = response.data.data[entry].relationships;
              if (relationships && relationships.roles) {
                const roles = relationships.roles.data;
                for (const role in roles) {
                  includedRoles.push(roles[role]);
                }
              }
            }
            const rolesList = this.getRolesList(includedRoles);
            this.SET_ROLEIDS(rolesList);
            return {
              list: response.data.data,
              currentPage: response.data.meta ? response.data.meta.current_page : 0,
              allPages: response.data.meta ? response.data.meta.last_page : 0,
            };
          }
        });
    },

    requestInterventionsOfStudy: async function(studyId, page, filterTerm, getAll, oldIntId, showLastPage) {
      let queryString;
      if (getAll) {
        queryString = "&limit=0";
      } else if (typeof page === "undefined" || page === "" || page <= 0) {
        queryString = "&page=1";
      } else {
        queryString = "&page=" + page;
      }
      const url = `${this.$url}/studies/${studyId}/interventions?${queryString}${filterTerm}`;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config)
        .then((response) => {
          if (
            response.data.meta &&
            (response.data.meta.current_page > response.data.meta.last_page ||
              showLastPage && response.data.meta.current_page != response.data.meta.last_page)
          ) {
            return this.requestInterventionsOfStudy(studyId, response.data.meta.last_page, filterTerm, getAll, oldIntId, showLastPage);
          } else {
            const interventionListData = {
              list: response.data.data,
              currentPage: response.data.meta ? response.data.meta.current_page : 0,
              allPages: response.data.meta ? response.data.meta.last_page : 0,
              total: response.data.meta ? response.data.meta.total : 0,
            };
            if (getAll) {
              const index = interventionListData.list.findIndex(elem => Number(elem.id) === oldIntId);
              if (index !== -1) {
                interventionListData.currentPage = Math.ceil((index + 1) / this.$perPage);
              }
              interventionListData.allPages = Math.ceil(interventionListData.total / this.$perPage);
              const begin = this.$perPage * (interventionListData.currentPage - 1);
              const number = this.$perPage * interventionListData.currentPage;
              interventionListData.list = interventionListData.list.slice(begin, number);
            }
            return interventionListData;
          }
        });
    },

    requestDiariesOfStudy: async function(studyId, page, filterTerm, oldDiaryId, showLastPage) {
      //page=0 represents limit=0 and no page set
      let queryString;
      if (page === 0) {
        queryString = "limit=0";
      } else if (typeof page === "undefined" || page === "" || page < 0) {
        queryString = "page=1";
      } else {
        queryString = "page=" + page;
      }
      const url = `${this.$url}/studies/${studyId}/diaries?${queryString}${filterTerm}`;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config)
        .then((response) => {
          if (
            response.data.meta &&
            (response.data.meta.current_page > response.data.meta.last_page ||
              showLastPage && response.data.meta.current_page != response.data.meta.last_page)
          ) {
            return this.requestDiariesOfStudy(studyId, response.data.meta.last_page, filterTerm, oldDiaryId, showLastPage);
          } else {
            const diaryListData = {
              list: response.data.data,
              currentPage: response.data.meta ? response.data.meta.current_page : 0,
              allPages: response.data.meta ? response.data.meta.last_page : 0,
              total: response.data.meta ? response.data.meta.total : 0,
            };
            if (page === 0 && oldDiaryId != -1) { //limit=0 & specific page should be shown, page number is not known, only diaryid
              const index = diaryListData.list.findIndex(elem => Number(elem.id) === oldDiaryId);
              if (index !== -1) {
                diaryListData.currentPage = Math.ceil((index + 1)/this.$perPage);
              }
              diaryListData.allPages = Math.ceil(diaryListData.total/this.$perPage);
              const begin = this.$perPage * (diaryListData.currentPage - 1);
              const number = this.$perPage * diaryListData.currentPage;
              diaryListData.list = diaryListData.list.slice(begin, number);
            }
            return diaryListData;
          }
        });
    },

    requestResetPasswordInstructions: async function(email) {
      const url = this.$url + "/auth/password/reset/instructions";
      const json = {
        data: {
          type: "users",
          attributes: { email },
        },
      };
      axios.post(url, json)
        .then(() => {
          this.SET_NOTIFICATIONTEXT({
            type: "success",
            text: Vue.i18n.translate("resetPasswordTranslation.resetPasswordSuccess") + email + ". " +
              Vue.i18n.translate("resetPasswordTranslation.resetPasswordSuccessHint"),
          });
        })
        .catch((error) => {
          //should not return 400 if no account for this email
          if (error.response && error.response.status.toString() === "400" && error.response.data.errors[0].code == "4") {
            this.SET_NOTIFICATIONTEXT({
              type: "success",
              text: Vue.i18n.translate("resetPasswordTranslation.resetPasswordSuccess") + email + ". " +
                Vue.i18n.translate("resetPasswordTranslation.resetPasswordSuccessHint"),
            });
          } else {
            this.handleErrorsNotLoggedIn(error, "resetPW");
          }
        });
    },

    requestStudyDetails: async function(studyId) {
      const url = `${this.$url}/editor/studies/${studyId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then((response) => response.data.data.attributes);
    },

    requestInterventionDetails: async function(interventionId) {
      const url = `${this.$url}/editor/interventions/${interventionId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const interventionDetails = response.data.data.attributes;
          if (!interventionDetails.locales || interventionDetails.locales.length === 0) {
            interventionDetails.locales = ["de", "en"];
          }
          return interventionDetails;
        });
    },

    requestInterventionTranslationStatus: async function(interventionId) {
      const url = `${this.$url}/interventions/${interventionId}/translation/status`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then((response) => response.data.data.attributes.translation_status);
    },

    requestLessonsOfIntervention: async function(
      interventionId,
      page,
      userCanEdit,
      getSpecificPage,
      oldLessonId,
      copyLessonId,
      includeTranslationStatus,
    ) {
      //page=0 represents limit=0 and no page set
      let queryString;
      if (page === 0) {
        queryString = "&limit=0";
      } else if (typeof page === "undefined" || page === "" || page < 0) {
        queryString = "&page=1";
      } else {
        //queryString = "&page=" + page + "&limit=3";
        queryString = "&page=" + page;
      }
      queryString += includeTranslationStatus ? "&include=translation_status" : "";
      const url = `${this.$url}/interventions/${interventionId}/questionnaires?sort=position${queryString}`;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        }
      };
      return axios.get(url, config)
        .then(async (response) => {
          if (response.data.meta && response.data.data.length > 0 && response.data.meta.current_page > response.data.meta.last_page) {
            return this.requestLessonsOfIntervention(
              interventionId,
              response.data.meta.last_page,
              userCanEdit,
              getSpecificPage,
              oldLessonId,
              copyLessonId,
              includeTranslationStatus,
            );
          }
          if (userCanEdit) {
            const lessonsWithNewPos = await this.checkAndUpdateLessonPositions(response.data.data, page);
            if (lessonsWithNewPos.updated) {
              response.data.data = lessonsWithNewPos.lessons;
            }
          }
          const lessonListData = { //specific page with 15 elements
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            allLessons: JSON.parse(JSON.stringify(response.data.data)),
            total: response.data.meta ? response.data.meta.total : 0
          };
          if (page === 0) { //limit=0
            if (getSpecificPage || copyLessonId != "") {
              const index = lessonListData.list.findIndex(elem => Number(elem.id) === oldLessonId || Number(elem.id) === copyLessonId);
              if (index != -1) {
                lessonListData.currentPage = Math.ceil((index + 1) / this.$perPage);
              }
            }
            lessonListData.allPages = Math.ceil(lessonListData.total / this.$perPage);
            const begin = this.$perPage * (lessonListData.currentPage - 1);
            const number = this.$perPage * lessonListData.currentPage;
            lessonListData.list = lessonListData.list.slice(begin, number);
          }
          return lessonListData;
        });
    },

    checkAndUpdateLessonPositions: async function(lessons, page) {
      const updatedPositions = [];
      const addPreviousPage = page > 1 ? 15 * (page - 1) : 0;
      for (const l in lessons) {
        const lesson = lessons[l];
        const position = Number(l) + 1 + addPreviousPage;
        if (lesson.attributes.position != position) {
          lesson.attributes.position = position;
          updatedPositions.push({
            questionnaire_id: lesson.id,
            position: position,
          });
        }
      }
      if (updatedPositions.length > 0) {
        await this.updateLessonPositionsInInterventionRequest(updatedPositions);
      }
      return {
        updated: updatedPositions.length > 0,
        lessons: lessons,
      };
    },

    requestDiaryUsedInLessonsOfStudy: async function(diaryId, studyId) {
      const url = `${this.$url}/studies/${studyId}/questionnaires?limit=0&unlockDiaries=${diaryId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then((response) => response.data.data);
    },

    requestDiaryUsedInInterventionsOfStudy: async function(diaryId, studyId) {
      const url = `${this.$url}/studies/${studyId}/interventions?limit=0`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          return response.data.data.filter(
            intervention => intervention.attributes.unlock_diaries != null && intervention.attributes.unlock_diaries.includes(diaryId),
          );
        });
    },

    createLessonForInterventionRequest: async function(interventionId, jsonObject) {
      const url = `${this.$url}/editor/interventions/${interventionId}/questionnaires`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const location = response.headers.location;
          const newLessonId = location.slice(location.lastIndexOf("/") + 1);
          return parseInt(newLessonId, 10);
        });
    },

    copyLessonRequest: async function(lessonId, jsonObject, sameWorkgroup) {
      const suffix = sameWorkgroup ? "/in/workgroup/copy" : "/to/workgroup/copy";
      const url = `${this.$url}/editor/questionnaires/${lessonId}${suffix}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const location = response.headers.location;
          const newLessonId = location.slice(location.lastIndexOf("/") + 1);
          return parseInt(newLessonId, 10);
        });
    },

    deleteLessonRequest: async function(lessonId) {
      const url = `${this.$url}/editor/questionnaires/${lessonId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    updateInterventionConfigAfterDeleteLessonRequest: async function(interventionId, jsonObject) {
      const url = `${this.$url}/editor/interventions/${interventionId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, jsonObject, config);
    },

    requestPublishInterventionDetails: async function(interventionId, checkElements) {
      let url = `${this.$url}/editor/interventions/${interventionId}/details`;
      if (checkElements) {
        url += "?elements=true";
      }
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then(response => response.data.data.attributes);
    },

    requestLessonDetails: async function(lessonId) {
      const url = `${this.$url}/editor/questionnaires/${lessonId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const lessonDetails = response.data.data.attributes;
          if (!lessonDetails.locales || lessonDetails.locales.length === 0) {
            lessonDetails.locales = ["de", "en"];
          }
          return lessonDetails;
        });
    },

    // not used
    activateInterventionRequest: async function(interventionId) {
      const url = `${this.$url}/editor/interventions/${interventionId}/activate`;
      const json = { data: { } };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    requestSkillsOfIntervention: async function(interventionId, page, getAll) {
      let queryString;
      if (getAll) {
        queryString = "?limit=0";
      } else if (typeof page === "undefined" || page === "" || page <= 0) {
        queryString = "?page=1";
      } else {
        queryString = "?page=" + page;
      }
      const url = `${this.$url}/editor/interventions/${interventionId}/skills${queryString}`;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config)
        .then((response) => {
          const meta = response.data.meta;
          if (meta && meta.current_page > meta.last_page) {
            return this.requestSkillsOfIntervention(interventionId, meta.last_page, getAll);
          } else {
            return {
              list: response.data.data,
              currentPage: meta ? meta.current_page : 0,
              allPages: meta ? meta.last_page : 0,
              total: meta ? meta.total : 0,
            };
          }
        });
    },

    createSkillForInterventionRequest: async function(interventionId, jsonObject) {
      const url = `${this.$url}/editor/interventions/${interventionId}/skills`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const location = response.headers.location;
          const newSkillId = location.slice(location.lastIndexOf("/") + 1);
          return parseInt(newSkillId, 10);
        });
    },

    deleteSkillRequest: async function(skillId) {
      const url = `${this.$url}/editor/skills/${skillId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    requestSkillDetails: async function(skillId) {
      const url = `${this.$url}/editor/skills/${skillId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const skillDetails = response.data.data.attributes;
          if (!Array.isArray(skillDetails.locales) || skillDetails.locales.length === 0) {
            skillDetails.locales = ["de", "en"];
          }
          return skillDetails;
        });
    },

    updateSkillDetailsRequest: async function(details, skillId) {
      const url = `${this.$url}/editor/skills/${skillId}`;
      const json = {
        data: {
          type: "skills",
          attributes: details,
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    requestSkillElements: async function(skillId, copyElementsBool) {
      const url = `${this.$url}/editor/skills/${skillId}/elements`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const elements = response.data.data.attributes.elements;
          //filter duplicates
          let counter = 1;
          const newElements = [];
          for (const elem in elements) {
            if (elements[elem].position === counter) {
              counter++;
              newElements.push(elements[elem]);
            }
          }
          if (!copyElementsBool) {
            return this.jsonToProperties(newElements, false);
          } else {
            response.data.data.attributes.elements = newElements;
            return response;
          }
        });
    },

    createSkillElementsRequest: async function(skillId, jsonObject) {
      const url = `${this.$url}/editor/skills/${skillId}/elements`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => this.parseElements(response.data.data.attributes.elements, false));
    },

    updateQuestionnaireStructureLock: async function(questionnaireId, locked) {
      const url = `${this.$url}/editor/questionnaires/${questionnaireId}/lock`;
      const json = {
        data: {
          type: "questionnaires",
          attributes: { lock: locked },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    updateSkillStructureLock: async function(skillId, locked) {
      const url = `${this.$url}/editor/skills/${skillId}/lock`;
      const json = {
        data: {
          type: "skills",
          attributes: { lock: locked },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    //not used
    activateDiaryRequest: async function(diaryId) {
      const url = `${this.$url}/editor/diaries/${diaryId}/activate`;
      const json = { data: { } };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.patch(url, json, config)
        .then(() => {
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("diaryTranslation.publishDiarySuccess") });
        })
        .catch((error) => {
          this.handleErrors(error, () => { this.activateDiaryRequest(diaryId) }, "");
        });
    },

    createStudyRequest: async function(name) {
      const url = this.$url + "/studies";
      const jsonObject = {
        data: {
          type: "studies",
          attributes: {
            name: name,
            type: "workgroup",
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const location = response.headers.location;
          const newStudyId = location.slice(location.lastIndexOf("/") + 1);
          return newStudyId;
        });
    },

    createInterventionRequest: async function(studyId, name, locales) {
      const url = this.$url + "/editor/interventions";
      const jsonObject = {
        data: {
          type: "interventions",
          attributes: {
            name: name,
            type: "intervention",
            study_id: studyId,
            locales: locales,
            intervention_type: "unaccompanied",
            buddy_support: this.$enabledFeatures.includes("buddy") ? false : undefined,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const location = response.headers.location;
          const newInterventionId = location.slice(location.lastIndexOf("/") + 1);
          return newInterventionId;
        });
    },

    createDiaryRequest: async function(studyId, name, locales) {
      const url = this.$url + "/editor/diaries";
      const jsonObject = {
        data: {
          type: "diary",
          attributes: {
            name: name,
            study_id: studyId,
            locales: locales,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then(async (response) => {
          const location = response.headers.location;
          const diaryId = location.slice(location.lastIndexOf("/") + 1);
          const questionnaireId = await this.createLessonForDiaryRequest(diaryId);
          return { diaryId, questionnaireId };
        });
    },

    createLessonForDiaryRequest: async function(newDiaryId) {
      const url = `${this.$url}/editor/diaries/${newDiaryId}/questionnaires`;
      const jsonObject = {
        data: {
          type: "questionnaires",
          attributes: {
            name: "diary",
            position: 1,
            locales: [],
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, jsonObject, config)
        .then((response) => {
          const location = response.headers.location;
          const newLessonId = location.slice(location.lastIndexOf("/") + 1);
          return parseInt(newLessonId, 10);
        });
    },

    initDiaryDetails: function(diaryDetails, questionnaire) {
      if (!Array.isArray(diaryDetails.locales) || diaryDetails.locales.length === 0) {
        diaryDetails.locales = ["de", "en"];
      }
      diaryDetails.structure_lock = !!questionnaire.structure_lock;
      return diaryDetails;
    },

    requestDiary: async function(diaryId, questionnaireId) {
      const url = `${this.$url}/editor/diaries/${diaryId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      if (!questionnaireId && questionnaireId != 0) {
        return axios.get(url, config)
          .then(async (response) => {
            const diaryDetails = response.data.data.attributes;
            const questionnaire = await this.requestLessonDetails(diaryDetails.questionnaire_id);
            return this.initDiaryDetails(diaryDetails, questionnaire);
          });
      } else {
        return Promise.all([axios.get(url, config), this.requestLessonDetails(questionnaireId)])
          .then(async ([diaryResponse, questionnaire]) => {
            const diaryDetails = diaryResponse.data.data.attributes;
            if (diaryDetails.questionnaire_id != questionnaireId) {
              console.log(`Incorrect IDs: ${diaryDetails.questionnaire_id} ${questionnaireId}`);
              questionnaire = await this.requestLessonDetails(diaryDetails.questionnaire_id);
            }
            return this.initDiaryDetails(diaryDetails, questionnaire);
          });
      }
    },

    requestDiaryAndLesson: async function(diaryId, questionnaireId, getElements) {
      return this.requestDiary(diaryId, questionnaireId)
        .then(async (diaryDetails) => {
          if (getElements) {
            const elements = await this.requestLessonElementsOfDiary(diaryDetails.questionnaire_id);
            const result = this.jsonToProperties(elements, true);
            this.SET_LESSONELEMENTSARR(result.elements);
            if (this.getIsEditable && result.removedOldConditionalPage > 0) {
              this.SET_NOTIFICATIONTEXT({
                type: "error",
                text: Vue.i18n.translate(
                  "lessonTranslation.removedOldConditionalPage",
                  { counter: result.removedOldConditionalPage, pages: result.pages },
                ),
              });
            }
          }
          return diaryDetails;
        });
    },

    requestLessonElementsOfDiary: async function(lessonId) {
      const url = `${this.$url}/questionnaires/${lessonId}/elements`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const elements = response.data.data.attributes.elements;
          //filter duplicates
          let counter = 1;
          const newElements = [];
          for (const elem in elements) {
            if (elements[elem].position === counter) {
              counter++;
              newElements.push(elements[elem]);
            }
          }
          return newElements;
        });
    },

    //default_configuration: original & copy get updated
    copyInterventionInStudyRequest: async function(interventionId, studyId, defaultConfig, locales, isTest) {
      const url = `${this.$url}/editor/interventions/${interventionId}/from/workgroup/copy`;
      const json = {
        data: {
          type: "interventions",
          attributes: {
            study_id: studyId,
            default_configuration: defaultConfig,
            is_test: isTest,
            locales: locales,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    copyInterventionInWorkgroupRequest: async function(interventionId, studyId, sameWorkgroup) {
      const json = {
        data: {
          type: "interventions",
          attributes: {
            study_id: studyId,
          },
        },
      };
      const suffix = sameWorkgroup ? "/in/workgroup/copy" : "/to/workgroup/copy";
      const url = `${this.$url}/editor/interventions/${interventionId}${suffix}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    copyDiaryRequest: async function(diaryId, studyId, sameWorkgroup) {
      const json = {
        data: {
          type: "diary",
          attributes: {
            study_id: studyId,
          },
        },
      };
      const suffix = sameWorkgroup ? "/in/workgroup/copy" : "/to/workgroup/copy";
      const url = `${this.$url}/editor/diaries/${diaryId}${suffix}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    copyStudyRequest: async function(studyId, name) {
      const json = {
        data: {
          type: "studies",
          attributes: { name },
        },
      };
      const url = `${this.$url}/editor/studies/${studyId}/copy`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    deleteInterventionRequest: async function(interventionId) {
      const url = `${this.$url}/editor/interventions/${interventionId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    deleteDiaryRequest: async function(diaryId) {
      const url = `${this.$url}/editor/diaries/${diaryId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    deleteStudyRequest: async function(studyId) {
      const url = `${this.$url}/editor/studies/${studyId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    exportInterventionRequest: async function(interventionId) {
      const url = `${this.$url}/editor/interventions/${interventionId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then(async (response) => {
          const lessons = await this.exportLessonsRequest(interventionId);
          const exportedData = {
            interventionDetails: response.data.data,
            lessons: lessons,
          };
          return exportedData;
        });
    },

    exportLessonsRequest: async function(interventionId) {
      const url = `${this.$url}/editor/interventions/${interventionId}/questionnaires?limit=0`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then(async (response) => {
          const arrLessons = [];
          for (const l in response.data.data) {
            // console.log(response.data.data[lesson].attributes.name)
            const lesson = response.data.data[l];
            const elements = await this.exportLessonElementsRequest(lesson.id);
            let checkElementsString = lesson.attributes.position + ": " + elements.length + " ";
            if (elements.length > 0) {
              checkElementsString += elements[elements.length - 1].position + " -> " + (elements.length === elements[elements.length - 1].position);
            } else {
              checkElementsString += "0 -> true";
            }
            console.log(checkElementsString);
            //filter duplicates
            let counter = 1;
            const newElements = [];
            for (const element of elements) {
              if (element.position === counter) {
                counter++;
                newElements.push(element);
              }
            }
            let blockCounter = 0;
            for (const element of newElements) {
              if (element.elementtype === "elements/blockopens") {
                blockCounter++;
              } else if (element.elementtype === "elements/blockcloses") {
                blockCounter--;
              } else if (element.elementtype === "elements/pages" && blockCounter > 0) {
                console.log("conditional page in lesson " + lesson.id);
              }
            }
            arrLessons.push({
              id: lesson.id,
              details: lesson.attributes,
              elements: newElements,
            });
          }
          return arrLessons;
        });
    },

    exportLessonElementsRequest: async function(id) {
      const url = `${this.$url}/questionnaires/${id}/elements`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const elements = response.data.data.attributes.elements;
          //filter duplicates
          let counter = 1;
          const newElements = [];
          //var filesToCopy = [];
          //var translation, filename;
          //var blockCounter = 0;
          // var lessonHasSliderError = 0;

          for (const elem in elements) {
            /*
            var studyId = 316;

            if(elements[elem].elementtype === "elements/media"){
              for(translation in elements[elem].translations){
                if(elements[elem].translations[translation].uri.startsWith("https://api.esano-trainings.de/uploads/media/")){
                  filesToCopy.push(
                    {
                      "url": elements[elem].translations[translation].uri,
                      "original_name": elements[elem].translations[translation].filename
                    }
                  );
                  filename = elements[elem].translations[translation].uri.split("").reverse().join("");
                  filename = filename.substr(0, filename.indexOf('/'));
                  filename = filename.split("").reverse().join("");
                  //console.log("lessonid: " + id);
                  //console.log("https://api.esano-trainings.de/uploads/studies/" + studyId + "/" + filename.split(".")[0])
                  elements[elem].translations[translation].uri = "https://api.esano-trainings.de/uploads/studies/" + studyId + "/" + filename.split(".")[0]
                }
              }
            }
            */

            // if(elements[elem].elementtype === "elements/blockopens"){
            //   blockCounter++;
            // }else if(elements[elem].elementtype === "elements/blockcloses"){
            //   blockCounter--;
            // }else if(elements[elem].elementtype === "elements/pages" && blockCounter > 0){
            //   console.log("lessonId: " + id + " pos:" + elements[elem].position);
            // }

            // if(elements[elem].elementtype === "elements/questions" && elements[elem].questiontype === "Slider"){
            //   for(var t in elements[elem].translations){
            //     if(elements[elem].translations[t].answers.length === 0){
            //       lessonHasSliderError++;
            //     }else{
            //       console.log(elements[elem].translations[t].answers[0].label)
            //       console.log(elements[elem].translations[t].answers[1].label)
            //     }
            //   }
            // }

            if (elements[elem].position === counter) {
              counter++;
              newElements.push(elements[elem]);
            }
          }

          // if(lessonHasSliderError > 0){
          //   console.log("lessonId: " + id + " : " + lessonHasSliderError)
          // }

          //console.log(JSON.stringify(filesToCopy))

          return newElements;
        });
    },

    exportDiaryRequest: async function(diaryId) {
      const url = this.$url + "/editor/diaries/" + diaryId;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then(async (response) => {
          const diaryDetails = response.data.data;
          const elements = await this.exportLessonElementsRequest(diaryDetails.attributes.questionnaire_id);
          //filter duplicates
          let counter = 1;
          const newElements = [];
          for (const element of elements) {
            if (element.position === counter) {
              counter++;
              newElements.push(element);
            }
          }
          const exportedData = {
            diaryDetails: diaryDetails,
            elements: newElements,
          };
          return exportedData;
        });
    },

    importInterventionRequest: async function(jsonObject, studyId) {
      const json = {
        data: {
          type: "interventions",
          attributes: jsonObject.interventionDetails.attributes,
        }
      };
      json.data.attributes.study_id = studyId;
      json.data.attributes.is_active = false;
      json.data.attributes.unlock_diaries = [];
      if (!json.data.attributes.picture || json.data.attributes.picture.startsWith("https://") || json.data.attributes.picture.startsWith("/var")) {
        json.data.attributes.picture = "";
      }
      //export from old cms has no translations array -> only exported in de
      if (!json.data.attributes.translations || json.data.attributes.translations.length === 0) {
        json.data.attributes.translations = [{
          locale: "de",
          title: json.data.attributes.title,
          description: json.data.attributes.description,
        }];
      }
      if (!json.data.attributes.intervention_type || json.data.attributes.intervention_type === "accompanied_and_unaccompanied") {
        json.data.attributes.intervention_type = "unaccompanied";
      }
      if (!this.$enabledFeatures.includes("buddy") && (json.data.attributes.buddy_support || json.data.attributes.buddy_support === 0)) {
        delete json.data.attributes["buddy_support"];
      } else if (this.$enabledFeatures.includes("buddy")) {
        json.data.attributes.buddy_support = false;
      }

      const url = this.$url + "/editor/interventions";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.post(url, json, config)
        .then((response) => {
          if (jsonObject.lessons.length > 0) {
            let string = JSON.stringify(jsonObject);
            let regEx;
            //replace all old links
            for (const oldUrl in this.$urlUploadsOld) {
              if (this.$urlUploadsOld[oldUrl] !== this.$urlUploads) {
                regEx = new RegExp(this.$urlUploadsOld[oldUrl], "g");
                string = string.replace(regEx, this.$urlUploads);
              }
            }
            //replace all links $urlUploads/studies/any-study-id/name.extension with current study id -> $urlUploads/studies/studyid/name.extension
            const newUrl = `${this.$urlUploads}/studies/${studyId}/$1`;
            let part1 = new RegExp(this.$urlUploads);
            let part2 = /\/studies\/[0-9]*\/([0-9a-z]*\.[a-zA-Z0-9]*)/;
            regEx = new RegExp(part1.source + part2.source, "g");
            string = string.replace(regEx, newUrl);
            //replace all links $urlUploads/studies/any-study-id/name with current study id -> $urlUploads/studies/studyid/name
            part1 = new RegExp(this.$urlUploads);
            part2 = /\/studies\/[0-9]*\/([0-9a-z]*)/;
            regEx = new RegExp(part1.source + part2.source, "g");
            string = string.replace(regEx, newUrl);
            //replace all old links: $urlUploads/media/name.extension with $urlUploads/studies/studyid/name.extension
            part1 = new RegExp(this.$urlUploads);
            part2 = /\/media\/([0-9]*\.[a-zA-Z0-9]*)/;
            regEx = new RegExp(part1.source + part2.source, "g");
            string = string.replace(regEx, newUrl);
            //replace all old links: $urlUploads/studies/name.extension with $urlUploads/studies/studyid/name.extension
            part1 = new RegExp(this.$urlUploads);
            part2 = /\/studies\/([0-9]*\.[a-zA-Z0-9]*)/;
            regEx = new RegExp(part1.source + part2.source, "g");
            string = string.replace(regEx, newUrl);
            jsonObject = JSON.parse(string);
            const config = jsonObject.interventionDetails.attributes.default_configuration.questionnaire_configuration;
            const location = response.headers.location;
            const newInterventionId = location.slice(location.lastIndexOf("/") + 1);
            this.importLessonsRequest(studyId, newInterventionId, jsonObject.lessons, [], {}, config);
          } else {
            this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.importInterventionSuccess") });
          }
        })
        .catch((error) => {
          this.handleErrors(error, () => this.importInterventionRequest(jsonObject, studyId), "");
        });
    },

    importLessonsRequest: async function(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config) {
      const details = {
        data: {
          type: "questionnaires",
          attributes: lessons[0].details,
        },
      };
      //export from old cms has no locales array
      if (!details.data.attributes.locales) {
        details.data.attributes.locales = ["de", "en"];
      }
      if (!details.data.attributes.page_color) {
        details.data.attributes.page_color = "";
      }
      if (details.data.attributes.page_color.startsWith("https://")) {
        const pageColor = details.data.attributes.page_color;
        const color = pageColor.slice(pageColor.lastIndexOf("/") + 1);
        details.data.attributes.page_color = color.split(".")[0];
      }
      details.data.attributes.unlock_diaries = [];
      //export from old cms has no translations array -> only exported in de
      if (
        (!details.data.attributes.translations || details.data.attributes.translations.length === 0) &&
        details.data.attributes.title !== undefined &&
        details.data.attributes.description !== undefined
      ) {
        details.data.attributes.translations = [{
          locale: "de",
          title: details.data.attributes.title,
          description: details.data.attributes.description,
        }];
      }
      const newTranslations = [];
      //check if title or description null
      for (const t in details.data.attributes.translations) {
        const translation = details.data.attributes.translations[t];
        if (translation.title) {
          newTranslations.push(JSON.parse(JSON.stringify(translation)));
        }
      }
      details.data.attributes.translations = newTranslations;
      const url = `${this.$url}/editor/interventions/${interventionId}/questionnaires`;
      const requestConfig = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.post(url, details, requestConfig)
        .then((response) => {
          const location = response.headers.location;
          const newLessonId = parseInt(location.slice(location.lastIndexOf("/") + 1), 10);
          lessonIds.push(newLessonId);
          oldNewLessonIds[lessons[0].id] = newLessonId;
          this.importLessonElementsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config);
        })
        .catch((error) => {
          this.handleErrors(error, () => this.importLessonsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config), "");
        });
    },

    importLessonElementsRequest: async function(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config) {
      const lessonElements = lessons[0].elements;
      for (const elem in lessonElements) {
        const element = lessonElements[elem];
        switch (element.elementtype) {
          case "elements/headlines":
            if (element.color === undefined) {
              element.color = "#000000";
            }
            break;
          case "elements/pages":
            if (!element.color) {
              element.color = "";
            }
            break;
          case "elements/questions":
            if (element.questiontype === "Slider" && element.values.start === undefined) {
              element.values.start = element.values.min;
            }
            break;
          default:
        }
      }
      const elements = {
        data: {
          type: "elements/elements",
          attributes: {
            elements: lessonElements,
          },
        },
      };
      const url = `${this.$url}/editor/questionnaires/${lessonIds[lessonIds.length - 1]}/elements`;
      const requestConfig = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.post(url, elements, requestConfig)
        .then(() => {
          lessons.shift();
          if (lessons.length > 0) {
            this.importLessonsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config);
          } else {
            // custom order is in order of imported lessons after import (so in order same order as lessons were created),
            // lessons are shown in order of position in lesson overview & configuration
            // only when publishing intervention the custom order is sorted in the order of the lesson positions
            const updateIntDetailsData = {
              data: {
                type: "interventions",
                attributes: {
                  default_configuration: {
                    questionnaire_configuration: [],
                    custom_order: lessonIds,
                  },
                },
              },
            };
            if (oldNewLessonIds[undefined] != undefined) { //old version, ids of lessons were not exported, config can not be updated accordingly, set after_previous & always
              const questionnaireConfiguration = updateIntDetailsData.data.attributes.default_configuration.questionnaire_configuration;
              for (const lesson in lessonIds) {
                const unlockType = questionnaireConfiguration.length === 0 ? "always" : "after_previous";
                questionnaireConfiguration.push({
                  id: parseInt(lessonIds[lesson], 10),
                  unlock_type: unlockType,
                  unlock_days_after_start: null,
                  feedback_required: false,
                });
              }
            } else { //update old config
              for (const lesson in config) {
                const lessonConfig = config[lesson];
                //replace old id with new one
                lessonConfig.id = oldNewLessonIds[lessonConfig.id];
                //for conditions replace questionnaire_id (only for lessons not diaries)
                if (lessonConfig.unlock_type === "conditional") {
                  const newConditions = [];
                  for (const c in lessonConfig.condition) {
                    const condition = lessonConfig.condition[c];
                    if (condition.diary_id === null) { //update lesson condition
                      condition.questionnaire_id = oldNewLessonIds[condition.questionnaire_id];
                      newConditions.push(condition);
                    }
                    //else: remove diary condition (diary is not imported with intervention)
                  }
                  //all conditions were for diaries -> all removed -> set unlock type to manually
                  if (newConditions.length === 0) {
                    lessonConfig.unlock_type = "manually";
                    lessonConfig.condition = null;
                    //also set intervention type to accompanied in this case
                    updateIntDetailsData.data.attributes.intervention_type = "accompanied";
                  } else { //conditions for diaries removed, some are for lessons, unlock type can stay conditional
                    lessonConfig.condition = newConditions;
                  }
                }
              }
            }
            updateIntDetailsData.data.attributes.default_configuration.questionnaire_configuration = config;
            this.updateInterventionDetailsAfterImportInterventionRequest(interventionId, updateIntDetailsData);
          }
        }).catch((error) => {
          this.handleErrors(
            error,
            () => this.importLessonElementsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config),
            "",
          );
        });
    },

    updateInterventionDetailsAfterImportInterventionRequest: async function(interventionId, updateIntDetailsData) {
      const url = this.$url + "/editor/interventions/" + interventionId;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.patch(url, updateIntDetailsData, config)
        .then(() => {
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.importInterventionSuccess") });
        })
        .catch((error) => {
          this.handleErrors(
            error,
            () => this.updateInterventionDetailsAfterImportInterventionRequest(interventionId, updateIntDetailsData),
            "",
          );
        });
    },

    importDiaryRequest: async function(jsonObject, studyId) {
      const diaryDetails = jsonObject.diaryDetails.attributes;
      diaryDetails.study_id = studyId;
      diaryDetails.is_active = false;
      if (!diaryDetails.picture || diaryDetails.picture.startsWith("https://") || diaryDetails.picture.startsWith("/var")) {
        diaryDetails.picture = "";
      }
      const json = {
        data: {
          type: "diary",
          attributes: diaryDetails,
        },
      };
      const url = this.$url + "/editor/diaries";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.post(url, json, config).then(async (response) => {
        const location = response.headers.location;
        const newDiaryId = location.slice(location.lastIndexOf("/") + 1);
        if (Array.isArray(diaryDetails.schedules) && diaryDetails.schedules.length > 0) {
          try {
            const schedules = diaryDetails.schedules.map(s => ({ ...s.schedule }));
            await this.createDiarySchedules(schedules, newDiaryId);
          } catch (error) {
            console.error(error);
          }
        }
        //create lesson for diary
        const newDiaryLessonId = await this.createLessonForDiaryRequest(newDiaryId);
        //create lesson elements for diary
        let string = JSON.stringify(jsonObject.elements);
        let regEx;
        //replace all old links
        for (const oldUrl in this.$urlUploadsOld) {
          if (this.$urlUploadsOld[oldUrl] !== this.$urlUploads) {
            regEx = new RegExp(this.$urlUploadsOld[oldUrl], "g");
            string = string.replace(regEx, this.$urlUploads);
          }
        }
        //replace all links $urlUploads/studies/any-study-id/name.extension with current study id -> $urlUploads/studies/studyid/name.extension
        let part1 = new RegExp(this.$urlUploads);
        let part2 = /\/studies\/[0-9]*\/([0-9a-z]*\.[a-zA-Z0-9]*)/;
        regEx = new RegExp(part1.source + part2.source, "g");
        string = string.replace(regEx, this.$urlUploads + "/studies/" + studyId + "/" + "$1");
        //replace all links $urlUploads/studies/any-study-id/name with current study id -> $urlUploads/studies/studyid/name
        part1 = new RegExp(this.$urlUploads);
        part2 = /\/studies\/[0-9]*\/([0-9a-z]*)/;
        regEx = new RegExp(part1.source + part2.source, "g");
        string = string.replace(regEx, this.$urlUploads + "/studies/" + studyId + "/" + "$1");
        const diaryElements = JSON.parse(string);
        for (const elem in diaryElements) {
          const element = diaryElements[elem];
          if (element.elementtype === "elements/headlines" && !element.color) {
            element.color = "#000000";
          } else if (element.elementtype === "elements/pages" && !element.color) {
            element.color = "";
          } else if (element.elementtype === "elements/questions" && element.questiontype === "Slider" && element.values.start === undefined) {
            element.values.start = element.values.min;
          }
        }
        const elementsJSON = {
          data: {
            type: "elements/elements",
            attributes: {
              elements: diaryElements,
            },
          },
        };
        await this.createLessonElementsRequest(newDiaryLessonId, elementsJSON);
      }).then(() => {
        this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("diaryTranslation.importDiarySuccess") });
      }).catch((error) => {
        this.handleErrors(error, () => this.importDiaryRequest(jsonObject, studyId), "");
      });
    },

    updateStudyDetailsRequest: async function(jsonObject, studyId) {
      const url = `${this.$url}/studies/${studyId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, jsonObject, config);
    },

    updateDiaryDetailsRequest: async function(jsonObject, diaryId) {
      const url = `${this.$url}/editor/diaries/${diaryId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, jsonObject, config);
    },

    createDiarySchedules: async function(schedules, diaryId) {
      const url = `${this.$url}/editor/diaries/${diaryId}/schedules`;
      const json = {
        data: {
          type: "diary",
          attributes: { schedules },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    deleteDiarySchedules: async function(scheduleIDs, diaryId) {
      const url = `${this.$url}/editor/diaries/${diaryId}/schedules`;
      const config = {
        data: {
          data: {
            type: "diary",
            attributes: { schedule_ids: scheduleIDs },
          },
        },
        headers: {
          Authorization: "Bearer " + this.getToken,
        },
      };
      return axios.delete(url, config);
    },

    updateInterventionDetailsRequest: async function(jsonObject, interventionId) {
      const url = `${this.$url}/editor/interventions/${interventionId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, jsonObject, config);
    },

    updateInterventionDetailsUnlockDiariesRequest: async function(interventionId, jsonObject) {
      const url = `${this.$url}/editor/interventions/${interventionId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, jsonObject, config);
    },

    updateUnlockDiariesForLessonsRequest: async function(data) {
      const url = this.$url + "/editor/questionnaires/diaries";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    updateLessonDetailsRequest: async function(details, lessonId) {
      const url = `${this.$url}/editor/questionnaires/${lessonId}`;
      const data = {
        data: {
          type: "questionnaires",
          attributes: details,
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    // not used
    deleteLessonDetailsTranslations: async function(locales, lessonId) {
      const url = `${this.$url}/editor/questionnaires/${lessonId}/translations`;
      const config = {
        data: {
          data: {
            type: "questionnaires",
            attributes: { locales },
          },
        },
        headers: {
          Authorization: "Bearer " + this.getToken,
        },
      };
      return axios.delete(url, config);
    },

    updateLessonPositionsInInterventionRequest: async function(jsonObject) {
      const url = this.$url + "/editor/questionnaires/positions";
      const data = {
        data: {
          type: "questionnaires",
          attributes: {
            new_positions: jsonObject,
          },
        },
      }
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    updateLessonSkillsInInterventionRequest: async function(jsonObject) {
      const json = {
        data: {
          type: "questionnaires",
          attributes: {
            new_skills: jsonObject,
          },
        },
      };
      const url = this.$url + "/editor/questionnaires/skills";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    requestMyRoles: async function() {
      const url = this.$url + "/my/roles";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.get(url, config)
        .then((response) => {
          const myRoles = [];
          if (response.data.data.length != 0) {
            for (const role in response.data.data) {
              switch(response.data.data[role].attributes.slug) {
                case "admin":
                  myRoles.push("admin")
                  break;
                case "editor":
                  myRoles.push("editor")
                  break;
                case "ecoach":
                  myRoles.push("ecoach")
                  break;
                default:
                  break;
              }
            }
          }
          if (myRoles.includes("admin") || myRoles.includes("editor")) {
            this.SET_ROLES(myRoles);
            this.requestMyUserId();
          } else {
            this.SET_ROLES(-1);
            this.SET_NOTIFICATIONTEXT({
              type: "error",
              text: Vue.i18n.translate("httpHelperTranslation.error") + Vue.i18n.translate("httpHelperTranslation.errorCode2"),
            });
            this.logoutRequest();
          }
        })
        .catch((error) => {
          this.handleErrors(error, () => { this.requestMyRoles(); }, "");
        });
    },

    requestMyUserId: async function() {
      const url = this.$url + "/my/profile";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.get(url, config)
        .then((response) => {
          this.SET_USERID(response.data.data.id);
          this.requestAllAvailableLocales();
        })
        .catch((error) => {
          this.handleErrors(error, () => { this.requestMyUserId(); }, "");
        });
    },

    requestMyProfile: async function() {
      const url = this.$url + "/my/profile";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then((response) => response.data.data);
    },

    updateMyProfileRequest: async function(json) {
      const url = this.$url + "/my/profile";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config).then(() => this.requestMyProfile());
    },

    /*
    roleIds:
    Study Owner (study.owner): 3
    Study Collaborator (study.collaborator): 4
    Study Access (study.access): 8
    Study eCoach Manager (study.ecoachmanager): 5
    Study eCoach (study.ecoach): 6
    */
    requestCollaboratorsIncludingPermissions: async function(queryString, studyId, isCollabView, idsOfChangedRoles, changedRoles) {
      const url = `${this.$url}/studies/${studyId}/collaborators?include=roles&${queryString}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const collaborators = response.data.data;
          const collaboratorIds = [];
          const collaboratorData = [];
          for (const user in collaborators) {
            const collaborator = collaborators[user];
            collaboratorIds.push(collaborator.id);
            const roleIds = [];
            const roles = collaborator.relationships.roles.data;
            for (const r in roles) {
              roleIds.push(roles[r].attributes.slug);
            }
            //get permission role
            let collaboratorRole;
            if (roleIds.includes("study.owner")) {
              collaboratorRole = "Study Owner";
              if (Number(collaborator.id) === Number(this.getUserId)) {
                this.SET_MYROLEFORSTUDY("Owner");
              }
            } else if (roleIds.includes("study.collaborator")) {
              collaboratorRole = "Study Collaborator";
              if (Number(collaborator.id) === Number(this.getUserId)) {
                this.SET_MYROLEFORSTUDY("Collaborator");
              }
            } else if (roleIds.includes("study.access")) {
              collaboratorRole = "Study Access";
              if (Number(collaborator.id) === Number(this.getUserId)) {
                this.SET_MYROLEFORSTUDY("Access");
              }
            } else {
              collaboratorRole = "none";
              if (Number(collaborator.id) === Number(this.getUserId)) {
                this.SET_MYROLEFORSTUDY("None");
              }
            }
            if (collaboratorRole !== "none") {
              let collabData = {
                firstname: collaborator.attributes.firstname,
                lastname: collaborator.attributes.lastname,
                name: collaborator.attributes.name,
                email: collaborator.attributes.email,
                id: collaborator.id,
                role: collaboratorRole,
                oldRole: collaboratorRole,
              };
              if (isCollabView) {
                //include permissions that changed on earlier visit of this view
                if (idsOfChangedRoles.includes(collaborator.id)) {
                  for (const id in idsOfChangedRoles) {
                    if (collaborator.id === idsOfChangedRoles[id]) {
                      collabData = changedRoles[id];
                    }
                  }
                }
              }
              collaboratorData.push(collabData);
            }
          }
          return {
            list: collaboratorData,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            idList: collaboratorIds,
          };
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status.toString() === "403") {
            this.SET_MYROLEFORSTUDY("None");
            return "";
          } else {
            return error;
          }
        });
    },

    requestAllEditorsIncludingPermissions: async function(collaboratorListData, page, filterTerm, idsOfChangedRoles, changedRoles) {
      const url = `${this.$url}/editor/users?page=${page}${filterTerm}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const editors = response.data.data;
          const editorList = [];
          for (const e in editors) {
            const editor = editors[e];
            let collaboratorRole;
            if (collaboratorListData.idList && collaboratorListData.idList.includes(editor.id)) {
              for (const collaborator in collaboratorListData.list) {
                if (collaboratorListData.list[collaborator].id === editor.id) {
                  collaboratorRole = collaboratorListData.list[collaborator].role;
                }
              }
            } else {
              collaboratorRole = "none";
            }
            editorList.push({
              firstname: editor.attributes.firstname,
              lastname: editor.attributes.lastname,
              name: editor.attributes.name,
              email: editor.attributes.email,
              id: editor.id,
              role: collaboratorRole,
              oldRole: collaboratorRole,
            });
          }
          //include permissions that changed on earlier visit of the permission page
          if (idsOfChangedRoles.length > 0) {
            for (const ed in editorList) {
              if (idsOfChangedRoles.includes(editorList[ed].id)) {
                for (const id in idsOfChangedRoles) {
                  if (editorList[ed].id === idsOfChangedRoles[id]) {
                     editorList[ed] = changedRoles[id];
                  }
                }
              }
            }
          }
          return {
            list: editorList,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
          };
        });
    },

    addCollaboratorsToStudyRequest: async function(usersList, studyId) {
      const url = `${this.$url}/studies/${studyId}/relationships/collaborators`;
      const json = {
        data: {
          type: "users",
          attributes: {
            users: usersList,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    removeCollaboratorsFromStudyRequest: async function(usersList, studyId) {
      const url = `${this.$url}/studies/${studyId}/relationships/collaborators`;
      const config = {
        data: {
          data: {
            type: "users",
            attributes: {
              users: usersList,
            },
          },
        },
        headers: {
          Authorization: "Bearer " + this.getToken,
        },
      };
      return axios.delete(url, config);
    },

    requestAllUsersIncludingPermissions: async function(page, searchTerm) {
      const url = `${this.$url}/admin/users?page=${page}${searchTerm}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          const users = response.data.data;
          const userList = [];
          for (const u in users) {
            const user = users[u];
            const currentUser = {
              firstname: user.attributes.firstname,
              lastname: user.attributes.lastname,
              name: user.attributes.name,
              email: user.attributes.email,
              id: user.id,
              verified: user.attributes.is_verified,
              role: [],
            };
            for (const role in user.attributes.roles) {
              const slug = user.attributes.roles[role].slug;
              if (slug === "admin" || slug === "ecoach" || slug === "editor") {
                currentUser.role.push(slug);
              }
            }
            userList.push(currentUser);
          }
          return {
            list: userList,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            total: response.data.meta ? response.data.meta.total : 0,
          };
        });
    },

    updateUserProfileAsAdmin: async function(userId, user) {
      const url = `${this.$url}/admin/users/${userId}`;
      const { name, firstname, lastname } = user;
      const data = {
        data: {
          type: "users",
          attributes: {
            name,
            firstname,
            lastname,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    addRolesToUserRequest: async function(userId, rolesToAdd) {
      const url = this.$url + "/admin/roles";
      const json = {
        data: {
          type: "roles",
          attributes: {
            user_id: userId,
            role_slugs: rolesToAdd,
          },
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    removeRolesFromUserRequest: async function(userId, rolesToRemove) {
      const url = this.$url + "/admin/roles";
      const config = {
        data: {
          data: {
            type: "roles",
            attributes: {
              user_id: userId,
              role_slugs: rolesToRemove,
            },
          },
        },
        headers: {
          Authorization: "Bearer " + this.getToken,
        },
      };
      return axios.delete(url, config);
    },

    deleteUserRequest: async function(userId) {
      const url = `${this.$url}/admin/users/${userId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    requestAccountDeletionRequests: async function(page, filterTerm) {
      const url = `${this.$url}/admin/users/delete/requests?include=users,studies&page=${page}${filterTerm}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config)
        .then((response) => {
          return {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
          };
        });
    },

    pseudonymizeUserRequest: async function(userId) {
      const url = `${this.$url}/admin/users/${userId}/pseudonymise`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    deleteUserCompletelyRequest: async function(userId) {
      const url = `${this.$url}/admin/users/${userId}/delete`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    declineAccountDeletionRequest: async function(userId) {
      const url = `${this.$url}/admin/users/delete/requests/${userId}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, {}, config);
    },

    requestStudyUploads: async function(studyId, page, filterTerm, pagesMedia, showLastPage, newAttributes) {
      const url = `${this.$url}/studies/${studyId}/media?page=${page}${filterTerm}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then(
        (response) => {
          if (response.data.meta && pagesMedia < response.data.meta.last_page && showLastPage) {
            return this.requestStudyUploads(
              studyId,
              response.data.meta.last_page,
              filterTerm,
              response.data.meta.last_page,
              showLastPage,
              newAttributes,
            );
          } else {
            const newStudyMediaFiles = response.data.data;
            for (const f in newStudyMediaFiles) {
              const file = newStudyMediaFiles[f];
              const newAttribute = newAttributes[file.id];
              if (newAttribute != undefined) {
                file.attributes.original_name = newAttribute.original_name;
                file.attributes.favorite = newAttribute.favorite;
              }
            }
            return {
              list: newStudyMediaFiles,
              currentPage: response.data.meta ? response.data.meta.current_page : 0,
              allPages: response.data.meta ? response.data.meta.last_page : 0,
            };
          }
        },
      );
    },

    requestStudyMedia: async function(studyId) {
      const url = `${this.$url}/studies/${studyId}/media?limit=0`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then((response) => response.data.data);
    },

    submitStudyMediaRequest: async function(formData, studyId) {
      const url = `${this.$url}/studies/${studyId}/media`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, formData, config);
    },

    deleteStudyMediaRequest: async function(json, studyId) {
      const url = `${this.$url}/studies/${studyId}/media`;
      const config = {
        data: json,
        headers: { Authorization: "Bearer " + this.getToken },
      };
      return axios.delete(url, config);
    },

    updateStudyMediaAttributesRequest: async function(json) {
      const url = this.$url + "/editor/studies/media/attributes";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    requestAllAvailableLocales: async function() {
      axios.get(this.$url + "/misc/locales")
        .then((response) => {
          const json = response.data.data;
          const availableLocalesArray = [];
          for (const locale in json) {
            availableLocalesArray.push(json[locale].attributes.locale);
          }
          this.SET_AVAILABLELOCALES(availableLocalesArray);
        })
        .catch((error) => {
          this.handleErrorsNotLoggedIn(error, "");
        });
    },

    requestAnnouncements: async function(page) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const queryString = "&client=cms&start<" + currentTime + "&end>" + currentTime + "&sort=-start";
      const url = `${this.$url}/announcements?include=user&page=${page}${queryString}`;
      const config = {
        headers: {
          Authorization: "Bearer " + this.getToken,
          "Accept-Language": this.getAppLanguage,
        },
      };
      return axios.get(url, config).then((response) => response.data.data);
    },

     requestAdminAnnouncements: async function(page, filterTerm) {
      const queryString = (filterTerm != "") ? "&" + filterTerm : "";
      const url = `${this.$url}/admin/announcements?include=user&page=${page}${queryString}`;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.get(url, config).then((response) => {
        return {
          list: response.data.data,
          currentPage: response.data.meta ? response.data.meta.current_page : 0,
          allPages: response.data.meta ? response.data.meta.last_page : 0,
          total: response.data.meta ? response.data.meta.total : 0,
        };
      });
    },

    createAnnouncementRequest: async function(json) {
      const url = this.$url + "/admin/announcements";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.post(url, json, config);
    },

    updateAnnouncementRequest: async function(id, json) {
      const url = this.$url + "/admin/announcements/" + id;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, json, config);
    },

    deleteAnnouncementRequest: async function(id) {
      const url = this.$url + "/admin/announcements/" + id;
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.delete(url, config);
    },

    logoutRequest: async function() {
      const url = this.$url + "/auth/logout";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      axios.delete(url, config)
        .then(() => this.CHANGE_TOKEN(""))
        .catch((error) => this.handleErrors(error, () => this.logoutRequest(), "logout"));
    }
  }
};
