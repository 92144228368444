export default {
  generalTranslation: {
    create: "Erstellen",
    save: "Speichern",
    saveLocales: "Speichern {locales}",
    copy: "Kopieren",
    choose: "Auswählen",
    delete: "Löschen",
    abort: "Abbrechen",
    publish: "Veröffentlichen",
    select: "Auswählen..",
    import: "Importieren",
    next: "Weiter",
    back: "Zurück",
    yes: "Ja",
    no: "Nein",
    of: "von",
    to: "bis",
    check: "Prüfen",
    chooseLanguage: "Sprache auswählen..",
    name: "Name",
    nameIntervention: "Interventionsname",
    email: "E-Mail",
    lesson: "Lektion",
    lessons: "Lektionen",
    creationDate: "Erstellungsdatum",
    ascending: "aufsteigend",
    descending: "absteigend",
    newStudy: "Neue Gruppe",
    createNewStudy: "Neue Gruppe erstellen",
    noResults: "Keine Treffer für diese Suche",
    noGroups: "Keine Gruppen vorhanden.",
    noGroupsInstruction: "Bitte über den Button oben rechts eine Gruppe erstellen oder von anderen Editoren zu einer bestehenden Gruppe hinzufügen lassen. In der Gruppe können dann Interventionen erstellt werden.",
    noGroupsInstructionModal: 'Bitte zuerst unter "Meine Gruppen" eine Gruppe erstellen.',
    noStudies: "Keine Studien vorhanden.",
    noStudiesInstruction: "Bitte von einem Admin eine Studie in der E-Coach Plattform erstellen lassen.",
    noInterventions: "Keine Interventionen vorhanden.",
    noInterventionsInstruction: 'Bitte über den Button oben rechts eine Intervention erstellen. Wenn bisher auch keine Gruppe vorhanden ist, diese zuerst unter "Meine Gruppen" erstellen.',
    noInterventionsInstructionStudy: "Bitte über den Button oben rechts eine Intervention erstellen.",
    noInterventionsInstructionModal: 'Bitte zuerst unter "Meine Interventionen" eine Intervention erstellen. Wenn bisher auch keine Gruppe vorhanden ist, diese davor unter "Meine Gruppen" erstellen.',
    noDiaries: "Keine Tagebücher vorhanden.",
    noDiariesTooltip: "Bitte über den Button oben rechts ein Tagebuch erstellen.",
    noLessons: "Keine Lektionen vorhanden.",
    noLessonsTooltip: "Bitte über den Button oben rechts eine Lektion erstellen.",
    noLessonElements: "Keine Elemente vorhanden.",
    noLessonElementsTooltip: "Elemente können über das + Symbol hinzugefügt werden.",
    noSkills: "Keine Fertigkeiten vorhanden.",
    noSkillsTooltip: "Bitte über den Button oben rechts eine Fertigkeit erstellen.",
    noTitle: "Kein Titel",
    noDescription: "Keine Beschreibung",
    showHideAnnouncements: "Ankündigungen ein-/ausblenden",
    leaveSiteWarning: "Seite wirklich verlassen?",
    unsavedChanges: "Die folgenden Seiten enthalten ungesicherte Änderungen",
    warning: "Alle ungesicherten Änderungen werden dann verworfen.",
    warningCreate: "Beim Erstellen werden alle ungesicherten Änderungen verworfen.",
    warningCopy: "Beim Kopieren werden alle ungesicherten Änderungen verworfen.",
    warningImport: "Beim Importieren werden alle ungesicherten Änderungen verworfen.",
    savingLoad: "Die {name} werden gespeichert..",
    savingSuccess: "Die {name} wurden gespeichert.",
    errorNameEmpty: "Name eingeben",
    errorSelectFile: "Datei auswählen",
    errorSelectStudy: "Studie auswählen",
    errorSelectWorkgroup: "Gruppe auswählen",
    errorSelectIntervention: "Intervention auswählen",
    errorSelectLesson: "Lektion auswählen",
    errorSelectDiary: "Tagebuch auswählen",
    errorAccept: "Checkliste akzeptieren",
    none: "Keine",
    privacy: "Datenschutz",
    imprint: "Impressum",
    characters: "Zeichen",
  },
  myStudiesTranslation: {
    myStudies: "Meine Gruppen",
    myInterventions: "Meine Interventionen",
    more: "Weitere",
    moreStudies: "Weitere Gruppen",
    moreStudiesExplanation: "Hier werden Gruppen ohne Berechtigungen angezeigt. Es sind nur die Interventionen bzw. Tagebücher der Gruppe sichtbar, jedoch keine weiteren Details. Zum Anfordern von Zugriffsrechten siehe jeweilige Gruppe > Details.",
    moreInterventions: "Weitere Interventionen",
    moreInterventionsExplanation: "Hier werden Interventionen ohne Berechtigungen angezeigt. Es sind keine weiteren Details einsehbar. Zum Anfordern von Zugriffsrechten siehe jeweilige Gruppe > Details.",
    filter: "Filtern",
    search: "Suche..",
    chooseSortOption: "Sortierung auswählen..",
    apply: "Anwenden",
    reset: "Zurücksetzen",
    details: "Details",
    activate: "Veröffentlichen",
    interventionActive: "Diese Intervention wurde bereits veröffentlicht.",
    deleteWarning: 'Soll die Gruppe "{name}" wirklich gelöscht werden?',
    warning: "Diese Aktion kann nicht widerrufen werden.",
    warningLanguages: "Die folgenden Übersetzungen der Lektion sind nicht vollständig: ",
    warningInterventionDetails: "Die Interventionsdetails wurden nicht ausgefüllt.",
    chooseLanguageToPublish: "Mindestens eine der verfügbaren Sprachen für die Veröffentlichung auswählen",
    activateText: "Hinweis: Nach dem Veröffentlichen kann die Intervention in der eCoach Plattform nicht mehr bearbeitet werden, im CMS bleibt diese weiterhin bearbeitbar. Test-Interventionen bzw. nicht bestätigte oder abgelehnte offizielle Interventionen können in der eCoach Plattform gelöscht werden.",
    activateMode: "Veröffentlichung der Intervention als",
    activateTest: "Test-Intervention (ohne echte Teilnehmer:innen)",
    activateReal: "offizielle Intervention (mit echten Teilnehmer:innen)",
    activateTestConfirm: "Hiermit wird bestätigt, dass zu dieser Test-Intervention keine echten Teilnehmer:innen hinzugefügt werden.",
    activateHint: "Mit der Veröffentlichung der Intervention wird bestätigt:",
    activateChecked: "Die Inhalte der Intervention wurden geprüft.",
    activateCritical: "Die Intervention enthält keine kritischen, gefährdenden oder unangemessenen Inhalte (Texte, Medien, Links).",
    activateEmergencyText: "In allen Lektionen oder in externen Unterlagen für die Teilnehmer:innen ist ein Notfall-Kontakt-Text enthalten bzw. ein solcher wird in dieser Intervention nicht benötigt.",
    activateIntro: "Die Intervention enthält eine Einführungslektion mit Erklärungen der Funktionen der eSano Patient:innen App bzw. diese sind in externen Unterlagen für die Teilnehmer:innen enthalten.",
    activateContact: "Die Intervention enthält einen Kontakt (z.B. Studienleiter:in) für inhaltliche Fragen bzw. Meldung von Problemen mit dem eCoach bzw. dieser ist in externen Unterlagen für die Teilnehmer:innen enthalten.",
    activateOtherPerson: "Die Veröffentlichung muss anschließend von einem Reviewer der Studie in der eCoach Plattform bestätigt werden.",
    lessonWarning: "Die Intervention kann nicht veröffentlicht werden, da sie keine Lektionen enthält.",
    loginWarning: "Bitte einloggen.",
    deleteStudyLoad: "Die Gruppe wird gelöscht..",
    deleteStudySuccess: "Die Gruppe wurde gelöscht.",
    studyHasInterventionsWarning: "Die Gruppe beinhaltet Interventionen und/oder Tagebücher. Diese werden beim Löschen der Gruppe ebenfalls gelöscht.",
    receiveAccess: "Zum Anfordern von Zugriffsrechten siehe Gruppendetails",
  },
  loginTranslation: {
    password: "Passwort",
    forgotPassword: "Passwort vergessen?",
    login: "Login",
    loginLoad: "Login..",
    errorEnterEmail: "E-Mail eingeben",
    errorEnterPassword: "Passwort eingeben",
    errorEnterValidEmail: "Gültige E-Mail Adresse eingeben",
    errorEnterValidPassword: "Passwort in korrektem Format eingeben (mindestens 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl, 1 Sonderzeichen)",
  },
  logoutTranslation: {
    logout: "Ausloggen",
    logoutLoad: "Logout..",
  },
  answerTranslation: {
    answer: "Antwort",
    answerText: "Text für Wert",
    label: "Bezeichner",
    answerTranslation: "Übersetzung der Antwort",
    answerTextTranslation: "Übersetzung des Texts für Wert",
  },
  studyTranslation: {
    createStudyLoad: "Die Gruppe wird erstellt..",
    createStudySuccess: "Die Gruppe wurde erstellt.",
    copyStudy: 'Gruppe "{name}" kopieren',
    newName: "Neuer Name",
    copyStudyLoad: "Die Gruppe wird kopiert..",
    copyStudySuccess: "Die Gruppe wurde kopiert.",
    studyDetails: "Gruppendetails",
    newInterv: "Neue Intervention",
    newDiary: "Neues Tagebuch",
    interventions: "Interventionen der Gruppe",
    diaries: "Tagebücher",
    permissions: "Berechtigungen",
    studyMedia: "Medien verwalten",
  },
  interventionTranslation: {
    newIntervention: "Neue Intervention erstellen",
    newInterventionGroup: 'Neue Intervention erstellen in Gruppe "{name}"',
    createInterventionLoad: "Die Intervention wird erstellt..",
    createInterventionSuccess: "Die Intervention wurde erstellt.",
    selectedStudy: "Ausgewählte Gruppe",
    backToStudySelection: "Zurück zur Gruppenauswahl",
    selectStudy: "Zum Erstellen der Intervention bitte eine Gruppe auswählen.",
    shownGroups: "Es werden nur Gruppen mit Owner- oder Bearbeitungsrechten angezeigt.",
    shownStudies: "Es werden nur Studien mit Owner- oder eCoachManagerrechten angezeigt.",
    shownInterventions: "Es werden nur Interventionen mit Owner- oder Bearbeitungsrechten angezeigt.",
    copyIntervention: 'Intervention "{name}" kopieren',
    selection: "Auswahl",
    checks: "Überprüfungen",
    problems: "Probleme",
    publication: "Studienauswahl",
    activateIntervention: 'Intervention "{name}" veröffentlichen {locales}',
    warningIntervention: 'Probleme der Intervention "{name}"',
    copyInstruction: "Zum Kopieren der Intervention bitte eine Gruppe auswählen.",
    activateInstruction: "Zum Veröffentlichen der Intervention bitte eine Studie der E-Coach Plattform auswählen.",
    copyInterventionLoad: "Die Intervention wird kopiert..",
    copyInterventionSuccess: "Die Intervention wurde kopiert.",
    publishInterventionLoad: "Die Intervention wird in die Studie der E-Coach Plattform veröffentlicht..",
    checkInterventionConfigurationAndTranslations: "Beide Überprüfungen können hier mit einem Klick durchgeführt werden. Die Checkboxen von oben werden hier ebenfalls verwendet, um eine einfache oder komplette Überprüfung der Übersetzungen bzw. die Überprüfung der komplexen Bedingungen der Konfiguration durchzuführen.",
    checkInterventionConfiguration: "Eine Überprüfung der Interventionskonfiguration umfasst die Korrektheit der Standard-Konfiguration und die Korrektheit von definierten Bedingungen von freischaltbaren Lektionen. Zusätzlich können komplexe Bedingungen überprüft werden, was unter Umständen zu längeren Wartezeiten führen kann.",
    checkInterventionConfigurationHeader: "Probleme der Konfiguration",
    checkInterventionConfigurationHasErrors: "Fehler: Die Interventionskonfiguration hat Fehler die dringend vor der Veröffentlichung behoben werden sollten.",
    checkInterventionConfigurationHasErrorsNoConditions: '"Keine Bedingungen sind für diese Lektion definiert" kann durch Veränderung des Freischaltetyps oder Hinzufügen einer Bedingungen zu der Lektion in der Konfiguration behoben werden.',
    checkInterventionConfigurationHasErrorsMissing: 'Fehlende Werte "Bedingung ist leer", "Bedingung hat keine verlinkte Lektion/Tagebuch", "Bedingung hat kein Bedingungstyp" und "Beschreibung der Bedingung ist leer" können in der Konfiguration gesetzt werden.',
    checkInterventionConfigurationHasErrorsComplexCondition: '"Inkorrekte Bedingung mit Frage(n)" ist eine invalide komplexe Bedingung. Die Bedingung kann in der Konfiguration neu gesetzt oder gelöscht werden.',
    checkInterventionConfigurationHasErrorsInvalidLink: '"Bedingung verweist auf eine invalide Lektion/Tagebuch" kann durch neusetzen oder löschen der dazugehörigen "Lektion oder Tagebuch für Bedingung" in der Konfiguration behoben werden.',
    checkInterventionConfigurationGeneralErrors: "Fehler der Standard-Konfiguration",
    checkInterventionConfigurationConditionErrors: "Fehler der Bedingungen von freischaltbaren Lektionen",
    checkInterventionConfigurationErrorEmptyCondition: "Bedingung in Position {position} ist leer",
    checkInterventionConfigurationErrorMissingId: "Bedingung {name} in Position {position} hat keine verlinkte Lektion/Tagebuch",
    checkInterventionConfigurationErrorMissingType: "Bedingung {name} in Position {position} hat keinen Bedingungstyp",
    checkInterventionConfigurationErrorMissingDescription: "Beschreibung der Bedingung in Position {position} ist leer",
    checkInterventionConfigurationErrorNoConditions: "Keine Bedingungen sind für diese Lektion definiert",
    checkInterventionConfigurationErrorInvalidCondition: "Inkorrekte Bedingung mit Frage(n) {name} in Position {position}",
    checkInterventionConfigurationErrorInvalidId: "Bedingung {name} in Position {position} verweist auf eine invalide Lektion/Tagebuch (invalide ID: {questionnaireId})",
    publishInterventionOfficialPublishError: "Eine offizielle Intervention kann nicht mit Fehlern veröffentlicht werden",
    publishInterventionBackToSelection: "Zurück zu Auswahl",
    publishInterventionBackToChecks: "Zurück zu Überprüfungen",
    publishInterventionCheckConfig: "Die Konfiguration der Intervention wird überprüft..",
    publishInterventionCheckConfigSuccess: "Die Überprüfung der Intervention hat keine Probleme gefunden",
    publishInterventionCheckConfigAndTranslationsSuccess: "Die Überprüfung der Übersetzungen und Konfiguration der Intervention hat keine Probleme gefunden",
    publishInterventionCheck: "Übersetzungen überprüfen",
    publishInterventionCompleteCheck: "Komplette Überprüfung",
    publishInterventionCompleteCheckTooltip: "Eine einfache Überprüfung der Intervention umfasst welche von den ausgewählten Sprachen {locales} von den dazugehörigen Lektionen/Fertigkeiten/Tagebüchern unterstützt wird. Eine komplette Überprüfung umfasst zusätzlich alle Elemente (Texte, Medien, Fragen, ...) der Intervention und kann unter Umständen zu längeren Wartezeiten führen.",
    publishInterventionProblemsHeader: "Probleme der Übersetzungen",
    publishInterventionLessonMissesLocale: "Nicht unterstützte Übersetzungen: [{locales}]",
    publishInterventionLessonNoLocale: "Keine der ausgewählten Übersetzungen wird unterstützt",
    publishInterventionLessonIncompleteElements: "Unvollständige Elemente",
    publishInterventionEmptyTitle: "Leerer Titel: [{locales}]",
    publishInterventionEmptyDescription: "Leere Beschreibung: [{locales}]",
    publishInterventionErrors: "Fehler: Die Intervention enthält Lektionen/Fertigkeiten/Tagebücher mit Fehlern die dringend vor der Veröffentlichung behoben werden sollten.",
    publishInterventionWarnings: "Warnungen: Die Intervention enthält Lektionen/Fertigkeiten/Tagebücher mit Werten die bei einer Veröffentlichung zu unbeabsichtigten Folgen, wie fehlendem Text, führen können.",
    publishInterventionWarningsLocales: '"Nicht unterstützte Übersetzungen" sind Sprachen die von der Lektion/Fertigkeit/Tagebuch fehlen. Sie sollten hinzugefügt und ihre Elemente ausgefüllt werden.',
    publishInterventionWarningsElements: '"Unvollständige Elemente" sind Elemente der Lektion/Tagebuch bzw. Teil der Fertigkeitsbeschreibung mit fehlendem Inhalt. Sie können gefunden werden, indem man zu den dazugehörigen Elementen navigiert und "Elemente prüfen" klickt.',
    publishInterventionWarningsPublish: "Die Intervention kann veröffentlicht werden falls nur Warnungen gefunden wurden, dies kann allerdings zu unbeabsichtigten Folgen führen.",
    publishInterventionSuccess: "Die Intervention wurde in die Studie der E-Coach Plattform veröffentlicht und muss von einem Reviewer freigegeben werden (siehe Review Tab).",
    showTranslation: "Übersetzung einblenden",
    hideTranslation: "Übersetzung ausblenden",
    lockedStructure: "Ausschließliche Bearbeitung der Übersetzungen der Elemente erlaubt",
    unlockedStructure: "Komplette Bearbeitung der Elemente erlaubt",
    structureLockButtonDisabled: 'Nur Benutzer mit Berechtigung "Owner" können das Schloss verändern',
    structureLockModalHeader: "Schloss für Bearbeitung der Elemente",
    structureLockModalChanges: "Alle Änderungen der Elemente von allen Mitwirkenden müssen gespeichert werden, bevor das Schloss verändert wird. Ansonsten können Änderungen verloren gehen.",
    structureLockModalUnlocked: "Soll ausschließlich die Bearbeitung der Übersetzungen der Elemente erlaubt sein?",
    structureLockModalUnlockedInfo: "Das Schließen des Schlosses erlaubt die parallele Bearbeitung der Übersetzungen der Elemente von unterschiedlichen Sprachen. Dies begrenzt die möglichen Änderungen auf die Übersetzungen der Elemente und Funktionalitäten wie das Hinzufügen eines Elements werden deaktiviert. Falls mehrere Benutzer allerdings die gleiche Sprache parallel bearbeiten würden, könnten Änderungen verloren gehen.",
    structureLockModalLocked: "Soll komplette Bearbeitung der Elemente erlaubt sein?",
    structureLockModalLockedInfo: "Das Öffnen des Schlosses beendet die parallele Bearbeitung der Übersetzungen der Elemente von unterschiedlichen Sprachen. Änderungen außerhalb der Übersetzungen, wie das Hinzufügen eines Elements, werden aktiviert. Falls mehrere Benutzer die Elemente parallel bearbeiten würden, könnten Änderungen verloren gehen.",
    structureLockModalLoading: "Schloss wird verändert..",
    structureLockModalSuccess: "Schloss wurde verändert.",
    lockedStructureSkill: "Ausschließliche Bearbeitung der Übersetzungen der Fertigkeitsbeschreibung erlaubt",
    unlockedStructureSkill: "Komplette Bearbeitung der Fertigkeitsbeschreibung erlaubt",
    structureLockModalHeaderSkill: "Schloss für Bearbeitung der Fertigkeitsbeschreibung",
    structureLockModalChangesSkill: "Alle Änderungen der Fertigkeitsbeschreibung von allen Mitwirkenden müssen gespeichert werden, bevor das Schloss verändert wird. Ansonsten können Änderungen verloren gehen.",
    structureLockModalUnlockedSkill: "Soll ausschließlich die Bearbeitung der Übersetzungen der Fertigkeitsbeschreibung erlaubt sein?",
    structureLockModalUnlockedInfoSkill: "Das Schließen des Schlosses erlaubt die parallele Bearbeitung der Übersetzungen der Fertigkeitsbeschreibung von unterschiedlichen Sprachen. Dies begrenzt die möglichen Änderungen auf die Übersetzungen der Fertigkeitsbeschreibung und Funktionalitäten wie das Hinzufügen eines Elements werden deaktiviert. Falls mehrere Benutzer allerdings die gleiche Sprache parallel bearbeiten würden, könnten Änderungen verloren gehen.",
    structureLockModalLockedSkill: "Soll komplette Bearbeitung der Fertigkeitsbeschreibung erlaubt sein?",
    structureLockModalLockedInfoSkill: "Das Öffnen des Schlosses beendet die parallele Bearbeitung der Übersetzungen der Fertigkeitsbeschreibung von unterschiedlichen Sprachen. Änderungen außerhalb der Übersetzungen, wie das Hinzufügen eines Elements, werden aktiviert. Falls mehrere Benutzer die Fertigkeitsbeschreibung parallel bearbeiten würden, könnten Änderungen verloren gehen.",
    name: "Name (nicht sichtbar für Teilnehmer:innen)",
    nameTooLong: "Name darf nicht mehr als 255 Zeichen haben",
    title: "Titel",
    titleTooLong: "Titel darf nicht mehr als 255 Zeichen haben",
    description: "Beschreibung",
    descriptionTooLong: "Beschreibung darf nicht mehr als 255 Zeichen haben",
    titleTranslation: "Übersetzung des Titels",
    descriptionTranslation: "Übersetzung der Beschreibung",
    addLanguage: "Weitere Sprachen hinzufügen",
    add: "Hinzufügen",
    note: "Hinweis",
    ok: "Ok",
    deletePicture: "Soll das Bild gelöscht werden?",
    unsavedChangesInterventionDetails: "Die Interventionsdetails enthalten ungesicherte Änderungen.",
    unsavedChangesConfigLeave: "Die Konfiguration enthält ungesicherte Änderungen. Bitte die Änderungen speichern oder erneut auf Abbrechen klicken.",
    unsavedChangesConfigLeaveInterventionEditor: "Die Konfiguration enthält ungesicherte Änderungen. Bitte Speichern oder Abbrechen klicken.",
    leave: "Verlassen",
    deleteSelectedLanguageWarning: "Die gerade ausgewählte Sprache kann nicht entfernt werden. Um die Sprache zu entfernen, bitte eine andere Sprache zur Anzeige auswählen.",
    showTranslationWarning: "Die Übersetzung kann nur eingeblendet werden, wenn in den Details mehr als eine Sprache ausgewählt wurde.",
    permissionContact: "Kontakt um Zugriffsrechte zu erhalten:",
    or: "oder",
    editableForOwner: "Hinweis: Die Konfiguration kann nur mit Owner Berechtigungen bearbeitet werden.",
    preview: "Vorschau",
    interventionDetails: "Interventionsdetails",
    image: "Bild",
    selectFile: "Datei auswählen..",
    languagesIntervention: "Sprachen der Intervention",
    interventionGuided: "Begleitete oder unbegleitete Intervention",
    guided: "begleitet",
    unguided: "unbegleitet",
    smallLayout: "Schmales Layout",
    participants: "Teilnehmer:innen der Intervention",
    addedByEcoach: "Werden nur vom E-Coach hinzugefügt",
    addedOrSubscribing: "Werden vom E-Coach hinzugefügt oder können sich selbst einschreiben",
    useBuddyFeature: "Buddy Feature verwenden?",
    buddyFeatureExplanation: 'Durch Aktivieren dieses Features können zwei Teilnehmer:innen miteinander verknüpft werden. Diese "Buddies" können sich dann gegenseitig einmal täglich an die Bearbeitung ihrer Tagebuchaufgaben erinnern.',
    useProgressBars: "Fortschrittsbalken in Lektionen anzeigen?",
    lessonsOfIntervention: "Zugehörige Lektionen",
    deleteLessonWarning: 'Soll die Lektion "{name}" wirklich gelöscht werden?',
    updatePositionsLessonsLoad: "Die Positionen der Lektionen werden gespeichert..",
    updatePositionsLessonsSuccess: "Die Positionen der Lektionen wurden gespeichert.",
    deleteLessonNoUnlockedLesson: "Es gibt keine andere Lektion mit Freischaltetyp {types} in der Standard-Konfiguration. Dadurch wird keine Lektion automatisch freigeschaltet. Der Freischaltetyp einer Lektion kann in der Konfiguration verändert werden.",
    deleteLessonAfterPrevious: 'Falls die erste Lektion gelöscht werden soll, darf die zweite Lektion nicht vom Freischaltetyp "Nach Abschluss der vorherigen Lektion freigeschaltet" sein.',
    deleteLessonLoad: "Die Lektion wird gelöscht..",
    deleteLessonSuccess: "Die Lektion wurde gelöscht.",
    deleteSkillWarning: 'Soll die Fertigkeit "{name}" wirklich gelöscht werden?',
    deleteSkillLoad: "Die Fertigkeit wird gelöscht..",
    deleteSkillSuccess: "Die Fertigkeit wurde gelöscht.",
    updateDiaryDetailsLoad: "Die Tagebuchdetails werden gespeichert..",
    updateDiaryDetailsSuccess: "Die Tagebuchdetails wurden gespeichert.",
    updateInterventionDetailsLoad: "Die Interventionsdetails werden gespeichert..",
    updateInterventionDetailsSuccess: "Die Interventionsdetails wurden gespeichert.",
    uploadStudyPictureLoad: "Das Medium wird hochgeladen..",
    uploadStudyPictureSuccess: "Das Medium wurde hochgeladen.",
    translationProgress: "Übersetzungsfortschritt",
    chooseProgress: "Übersetzungsfortschritt für..",
    total: "Gesamt",
    intervention: "Intervention",
    noProgress: "Kein Übersetzungsfortschritt vorhanden",
    updateInterventionConfigLoad: "Die Konfiguration wird gespeichert..",
    updateInterventionConfigSuccess: "Die Konfiguration wurde gespeichert.",
    selectLanguageWarning: "Sprache auswählen",
    export: "Exportieren",
    exportInterventionLoad: "Die Intervention wird exportiert..",
    exportInterventionSuccess: "Die Intervention wurde exportiert.",
    deleteWarning: 'Soll die Intervention "{name}" wirklich gelöscht werden?',
    unlocktype: "Freischalten der Lektion",
    chooseUnlocktype: "Freischaltetyp auswählen..",
    chooseDateType: "Tage/Wochen",
    dateTypeDays: "Tag(e)",
    dateTypeWeeks: "Woche(n)",
    feedback: "Feedback",
    feedbackRequired: "Benötigt",
    feedbackNotRequired: "Nicht benötigt",
    showLessonAs: "Lektion anzeigen als",
    normalLesson: "Normale Lektion",
    bonusLesson: "Bonuslektion",
    unlockDiaries: "Lektion schaltet folgende Tagebücher frei (beim Freischalten der Lektion)",
    unlockDiariesIntervention: "Intervention schaltet folgende Tagebücher frei",
    unlockDiariesInterventionExplanation: "(beim Freischalten der Intervention)",
    unlockDiariesEmpty: "Keine",
    unlockDiariesAdd: "Tagebuch hinzufügen",
    chooseDiary: "Tagebuch auswählen..",
    unlockLessons: "Konditionale Lektionen pro Dialog von Patient:in auswählbar",
    unlockLessonsMultiple: "Mehrere",
    unlockLessonsOne: "Eine",
    always: "Immer freigeschaltet",
    manually: "Manuelle Freischaltung durch E-Coach",
    afterPrevious: "Nach Abschluss der vorherigen Lektion freigeschaltet",
    conditionalAfterPrevious: "Nach Abschluss der vorherigen konditionalen Lektion freigeschaltet",
    atDate: "x Tage/Wochen nach Beginn der Intervention freigeschaltet",
    condition: "Automatische Freischaltung (konditional)",
    selectLessonOrDiary: "Lektion oder Tagebuch für Bedingung",
    conditionQuestion: "Bedingung mit Frage(n)",
    conditionLessonFinished: "Wenn Lektion abgeschlossen",
    noConditionSet: "Keine Bedingung festgelegt",
    conditionLesson: "Freischaltung, wenn:",
    descriptionCondition: "Beschreibung der Bedingung (für eCoach Plattform)",
    descriptionConditionShort: "Beschreibung der Bedingung",
    conditionExplanation: "Bedingungen aus einer/mehreren Lektionen/Tagebüchern schalten diese Lektion frei (Lektion a ODER b): ",
    addCondition: "+ Bedingung",
    removeCondition: "Bedingung löschen",
    addConditionError: "Keine weiteren Lektionen/Tagebücher vorhanden",
    removeConditionError: "Mindestens eine Bedingung notwendig",
    errorSelectLessonDiary: "Zuerst eine Lektion oder ein Tagebuch für die Bedingung auswählen.",
    errorNoQuestions: "Die ausgewählte Lektion/ das ausgewählte Tagebuch beinhaltet keine Fragen.",
    errorConditionsIncomplete: "Die Konfiguration für automatische Freischaltung ist nicht vollständig.",
    errorConfigNoUnlockedLesson: "Die Standard-Konfiguration muss mindestens eine Lektion mit folgenden Freischaltetyp beinhalten",
    errorConfigLockedLesson: 'Die Konfiguration beinhaltet die nicht freischaltbare Lektion "{position}\xA0{lesson}".', // \xA0 is non-breaking space
    errorConfigNotFoundLesson: 'Die Lektion hängt von Lektionen/Tagebüchern außerhalb der Standard-Konfiguration ab.',
    errorConfigAfterPreviousFirst: 'Eine Lektion die nach Abschluss der Vorherigen freigeschaltet wird, kann nicht an erster Stelle der Standard-Konfiguration stehen.',
    errorConfigCycle: 'Die Bedingungen der Lektion führen zu einem Zyklus und können niemals erfüllt werden.',
    errorConfigInvalidConditions: 'Die Bedingungen der Lektion können niemals erfüllt werden.',
    checkConfigAndTranslations: "Übersetzungen und Konfiguration überprüfen",
    checkConfig: "Konfiguration überprüfen",
    checkConfigComplexConditions: "Komplexe Bedingungen überprüfen",
    checkConfigLoad: "Konfiguration wird überprüft...",
    checkConfigLoadDiaries: "Überprüfe Tagebücher...",
    checkConfigValid: "Die Konfiguration hat keine Fehler.",
    checkConfigInvalid: "Die Konfiguration hat Fehler (siehe markierte Bedingungen unten).",
    inCustomOrder: "In Standard-Konfiguration enthaltene Lektionen",
    notInCustomOrder: "Nicht in Standard-Konfiguration enthaltene Lektionen",
    changeConfig: "Konfiguration anpassen",
    showConfig: "Konfiguration anzeigen",
    deleteInterventionLoad: "Die Intervention wird gelöscht..",
    deleteInterventionSuccess: "Die Intervention wurde gelöscht.",
    selectImport: "JSON Datei mit exportierter Intervention",
    importIntervention: "Intervention importieren",
    importInterventionHeader: 'Intervention in Gruppe "{name}" importieren',
    importInterventionLoad: "Die Intervention wird importiert..",
    importInterventionSuccess: "Die Intervention wurde importiert",
    customOrderAddAll: "Alle Lektionen hinzufügen",
    customOrderRemoveAll: "Alle Lektionen entfernen",
    setUnlocktypeForAll: "Für alle übernehmen",
    updateLessonPositions: "Positionen anpassen",
    unsavedChangesPositionsLeave: "Die Positionen enthalten ungesicherte Änderungen. Bitte die Änderungen speichern oder erneut auf Abbrechen klicken.",
    unsavedChangesPositionsLeaveInterventionEditor: "Die Positionen enthalten ungesicherte Änderungen. Bitte Speichern oder Abbrechen klicken.",
    updatePositionsDragDrop: "Positionen per Drag and Drop anpassen",
    interventionGuidedSelected:"Die Intervention ist begleitet (anpassbar in den Interventionsdetails).",
    interventionUnguidedSelected:"Die Intervention ist unbegleitet. Deshalb stehen nicht alle Konfigurationsmöglichkeiten zur Auswahl. Diese Einstellung kann in den Interventionsdetails angepasst werden.",
    noDiariesInStudy: "In dieser Gruppe sind keine Tagebücher vorhanden.",
    noMoreDiariesInStudy: "In dieser Gruppe sind keine weiteren Tagebücher vorhanden.",
    sensing: "Aufzeichnung von Sensordaten (nur Android- und iOS-Version der Patient:innen-App)",
    sensingHint: "Sensordaten, die von der Android- und iOS-Version der Patient:innen-App beim Abschließen des Tagebuchs aufgezeichnet werden sollen. Die Berechtigungen zur Aufzeichnung werden auf dem Endgerät einzeln erfasst und können jederzeit widerrufen werden. Nur verfügbar, falls Teilnehmende die Android- oder iOS-Version der Patient:innen-App verwenden.",
    sensingLocation: "Standort (GPS, Längengrad wird anonymisiert)",
    sensingPedometer: "Schrittzähler",
    schedule: "Standard Bearbeitungszyklus des Tagebuchs (Benachrichtigungen)",
    scheduleHint: "Es wird definiert, wann Benachrichtigungen zur Erinnerung an das Ausfüllen versendet werden. Das Tagebuch kann unabhängig davon beliebig oft ausgefüllt werden.",
    perWeek: "mal pro Woche",
    daily: "Täglich",
    weekly: "Wöchentlich",
    monthly: "Monatlich",
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    unsavedChangesConfig: "Interventionskonfiguration",
    skills: "Fertigkeiten",
    skill: "Fertigkeit",
    createSkill: "Neue Fertigkeit",
    createSkillHeader: 'Neue Fertigkeit erstellen für Intervention "{name}"',
    createSkillLoad: "Die Fertigkeit wird erstellt..",
    createSkillSuccess: "Die Fertigkeit wurde erstellt.",
    selectSkillColor: "Fertigkeitsfarbe",
    useThemeColor: "Farbe abhängig von Theme",
    selectColor: "Farbe wählen",
    skillIcon: "Fertigkeitsicon",
    skillIconType: "SVG-Datei empfohlen",
    selectSkillIcon: "Fertigkeitsicon auswählen",
    removeSkillIcon: "Fertigkeitsicon löschen",
    skillDetails: "Fertigkeitsdetails",
    skillDescription: "Fertigkeitsbeschreibung",
    updateSkillDetailsLoad: "Die Fertigkeitsdetails werden gespeichert..",
    updateSkillDetailsSuccess: "Die Fertigkeitsdetails wurden gespeichert.",
    updateSkillElementsLoad: "Die Fertigkeitsbeschreibung wird gespeichert..",
    updateSkillElementsSuccess: "Die Fertigkeitsbeschreibung wurde gespeichert.",
    updatePositionsSkillsLoad: "Die Positionen der Fertigkeiten werden gespeichert..",
    updatePositionsSkillsSuccess: "Die Positionen der Fertigkeiten wurden gespeichert.",
    updateSkills: "Fertigkeiten festlegen",
    updateSkillsText: "Fertigkeiten für Lektionen festlegen",
    updateSkillsLessonsLoad: "Die Zuordnung der Fertigkeiten wird gespeichert..",
    updateSkillsLessonsSuccess: "Die Zuordnung der Fertigkeiten wurde gespeichert.",
    unsavedChangesSkillsLeave: "Die Zuordnung der Fertigkeiten enthält ungesicherte Änderungen. Bitte die Änderungen speichern oder erneut auf Abbrechen klicken.",
    unsavedChangesSkillsLeaveInterventionEditor: "Die Zuordnung der Fertigkeiten enthält ungesicherte Änderungen. Bitte Speichern oder Abbrechen klicken.",
    languagesSkill: "Sprachen der Fertigkeit",
    skillsForLesson: "Fertigkeiten für Lektion",
    noSkillsSelected: "Keine Fertigkeiten ausgewählt",
    addSkills: "Fertigkeiten hinzufügen",
    selectSkill: "Fertigkeit auswählen",
    noMoreSkills: "Keine weiteren Fertigkeiten vorhanden",
  },
  schedules: {
    manageReminders: "Erinnerungen verwalten",
    reminderAfterMinutes: "Erinnerungen nach X Minuten",
    start: "Start",
    end: "Ende",
    smallerThan: "ist kleiner als",
    expireInSeconds: "Abgelaufen nach X Sekunden",
    dayOfMonth: "Monatstag",
    dayOfWeek: "Wochentag",
    timeOfDay: "Tageszeit",
    type: "Typ",
    addSchedule: "Benachrichtigungen hinzufügen",
    addingSchedule: "Benachrichtigungen werden hinzugefügt...",
    addedSchedule: "Benachrichtigungen wurden hinzugefügt",
    amount: "Anzahl",
    deleteSchedule: "Benachrichtigung löschen",
    deleteScheduleText: "Benachrichtigung wirklich löschen?",
    schedulesErrorsText: "Für Benachrichtigungen",
    schedulesErrorsPositionText: "Position",
    schedulesAlreadyDeleted: "Es gab {length} Benachrichtigungen, die von einem anderen Benutzer gelöscht wurden. Diese wurden entfernt und es muss erneut gespeichert werden.",
    schedulesUnexpectedError: "Es gab unerwartete Fehler für die Benachrichtigungen. Die Änderungen der Benachrichtigungen wurden verworfen und es muss erneut gespeichert werden",
  },
  interventionPreviewTranslation: {
    start: "Start",
    outro: "Outro",
    selectLesson: "Lektion auswählen..",
    startIntervention: "Intervention starten",
  },
  lessonTranslation: {
    createLesson: "Neue Lektion",
    createLessonHeader: 'Neue Lektion erstellen in Intervention "{name}"',
    createLessonLoad: "Die Lektion wird erstellt..",
    createLessonSuccess: "Die Lektion wurde erstellt.",
    copyLesson: 'Lektion "{name}" kopieren',
    copyInstruction: "Zum Kopieren der Lektion bitte eine Intervention auswählen.",
    copyLessonLoad: "Die Lektion wird kopiert..",
    copyLessonSuccess: "Die Lektion wurde kopiert.",
    languagesLesson: "Sprachen der Lektion",
    lessonDetails: "Lektionsdetails",
    lessonElements: "Elemente der Lektion",
    selectElements: "Elemente auswählen",
    selectElementsAbort: "Elementauswahl aufheben",
    copyElements: "Elemente kopieren",
    selectIntervention: "Zum Kopieren der Elemente bitte zuerst eine Intervention auswählen.",
    selectDiary: "Zum Kopieren der Elemente bitte ein Tagebuch auswählen.",
    selectLesson: "Nun eine Lektion der Intervention auswählen.",
    backToInterventionSelection: "Zurück zur Interventionsauswahl",
    openAll: "Alle aufklappen",
    closeAll: "Alle zuklappen",
    toggleDragDropTrue: "Drag & Drop einblenden",
    toggleDragDropFalse: "Drag & Drop ausblenden",
    selectPage: "Seite auswählen",
    page: "Seite",
    allPages: "Alle Seiten",
    addNewElement: "Neues Element hinzufügen",
    addElementsDragDrop: "Elemente per Drag & Drop hinzufügen",
    addNewElementText: "Elemente über + oder per Drag & Drop hinzufügen",
    addHeadline: "Überschrift",
    addText: "Text",
    addMedia: "Medien",
    addQuestion: "Frage",
    addPage: "Seite",
    addLine: "Linie",
    addSpace: "Abstand",
    addTable: "Tabelle",
    addBlock: "Block",
    saveIndividualLesson: "Übersetzungen speichern {languages}",
    saveLesson: "Elemente speichern",
    checkLessonTranslations: "Übersetzungen für die Überprüfung auswählen",
    checkLessonNoTranslations: "Ohne Übersetzungen werden nur Werte für die Entwicklung und keine der später angezeigten Texte überprüft.",
    checkLesson: "Elemente prüfen",
    deleteLanguage: "Sprache löschen",
    deleteLanguageConfirmHeader: "Sprache löschen",
    deleteLanguageConfirm: "Sprache '{language}' wirklich löschen?",
    unsavedChangesInterventionDetails: "Die Interventionsdetails enthalten ungesicherte Änderungen. Bestätige erneut um Änderungen zu verwerfen oder klicke Abbrechen.",
    unsavedChangesLessonDetails: "Die Lektionsdetails enthalten ungesicherte Änderungen. Bestätige erneut um Änderungen zu verwerfen oder klicke Abbrechen.",
    unsavedChangesLessonElements: "Die Lektionselemente enthalten ungesicherte Änderungen.",
    updateLessonElementsLoad: "Die Elemente der Lektion werden gespeichert..",
    updateLessonElementsSuccess: "Die Elemente der Lektion wurden gespeichert.",
    updateDiaryElementsLoad: "Die Elemente des Tagebuchs werden gespeichert..",
    updateDiaryElementsSuccess: "Die Elemente des Tagebuchs wurden gespeichert.",
    requestElementsFail: "Die Elemente konnten nicht vom Server abgerufen werden.",
    checkElementsLoad: "Die Elemente werden geprüft..",
    checkElementsComplete: "Die Elemente sind vollständig.",
    checkElementsIncomplete: "Die Elemente sind unvollständig, siehe Markierungen.",
    updateInterventionDetailsLoad: "Die Interventionsdetails werden gespeichert..",
    updateInterventionDetailsSuccess: "Die Interventionsdetails wurden gespeichert.",
    updateLessonDetailsLoad: "Die Lektionsdetails werden gespeichert..",
    updateLessonDetailsSuccess: "Die Lektionsdetails wurden gespeichert.",
    copyLessonElementsLoad: "Die Elemente werden kopiert..",
    copyLessonElementsSuccess: "Die Elemente wurden kopiert.",
    errorStructureLockChanges: "Die Elemente haben Änderungen außerhalb der Übersetzungen, obwohl nur Übersetzungen verändert werden dürfen. Um die Änderungen speichern zu können, muss die komplette Bearbeitung der Elemente erlaubt sein. Sonst können die Änderungen durch Neuladen der Elemente verworfen werden.",
    errorElements: "Die Elemente sind fehlerhaft. Bitte die Fehler korrigieren und erneut speichern. Hinweis: Fehler können sich auch auf anderen Seiten befinden als der angezeigten.",
    errorElementsLabels: "Die Elemente sind fehlerhaft. Alle Frage-Labels müssen unterschiedlich sein. Bitte die Fehler korrigieren und erneut speichern. Hinweis: Fehler können sich auch auf anderen Seiten befinden als der angezeigten.",
    warningDeleteQuestion: "Die Frage wird in mindestens einer komplexen Block Bedingung verwendet. Wenn die Frage gelöscht wird, sind die komplexen Bedingungen unvollständig und müssen überarbeitet werden.",
    warningDeleteBlockQuestion: "Der Block enthält mindestens eine Frage, die in einer komplexen Block Bedingung verwendet. Wenn der Block gelöscht wird, sind die komplexen Bedingungen unvollständig und müssen überarbeitet werden.",
    warningeDeletePage: "Alle Elemente der Seite werden ebenfalls gelöscht. Falls Fragen auf dieser Seite in Bedingungen oder Referenzen auf anderen Seiten verwendet werden, werden diese unvollständig und müssen überarbeitet werden.",
    deleteQuestionWarning: 'Soll die Frage "{name}" wirklich gelöscht werden?',
    deleteBlockWarning: 'Soll der Block wirklich gelöscht werden?',
    deletePageWarning: "Soll die Seite wirklich gelöscht werden?",
    errorElementsCondition: "Die Frage-Elemente sind nicht vollständig. Bitte die Fehler korrigieren und erneut versuchen. Hinweis: Fehler können sich auch auf anderen Seiten befinden als der angezeigten.",
    errorElementsLabelsCondition: "Die Elemente sind fehlerhaft. Alle Frage-Labels müssen unterschiedlich sein. Bitte die Fehler korrigieren und erneut versuchen. Hinweis: Fehler können sich auch auf anderen Seiten befinden als der angezeigten.",
    removeElement: "Element löschen",
    duplicateElement: "Element duplizieren",
    changePosition: "Position ändern",
    changePositionInBlockUp: "Element in oberen Block verschieben",
    changePositionInBlockDown: "Element in unteren Block verschieben",
    changePositionOutBlockUp: "Element über Block verschieben",
    changePositionOutBlockDown: "Element unter Block verschieben",
    dragAndDrop: "Position per Drag and Drop ändern",
    copyElementsErr: "Die Elemente können nicht kopiert werden.",
    copyElementsQuestionsMissing: "Einige Fragen werden für Bedingungen/ Wiederholungen/ Referenzen ebenfalls benötigt. Die folgenden Fragen müssen ebenfalls kopiert werden:",
    copyElementsBlockOpenMissing: "Es kann kein Blockende ohne Blockanfang kopiert werden.",
    copyElementsBlockCloseMissing: "Es kann kein Blockanfang ohne Blockende kopieren werden.",
    saveElementsPrompt: "Lektionselemente vor dem Kopieren speichern.",
    saveDiaryElementsPrompt: "Tagebuchelemente vor dem Kopieren speichern.",
    saveDetailsPrompt: "Lektionsdetails vor dem Kopieren speichern.",
    saveDiaryDetailsPrompt: "Tagebuchdetails vor dem Kopieren speichern.",
    saveElementsAndDetailsPrompt: "Lektionselemente und -details vor dem Kopieren speichern.",
    saveDiaryElementsAndDetailsPrompt: "Tagebuchelemente und -details vor dem Kopieren speichern.",
    back: "Zurück",
    previousPage: "Vorherige Seite",
    nextPage: "Nächste Seite",
    endLesson: "Lektion abschließen",
    startLesson: "Ausfüllen",
    selectDefaultPageColor: "Standard Hintergrund der Seiten",
    selectColor: "Einfarbig",
    selectBackground: "Bild",
    questionsRequired: "Abschicken der Lektion auch ohne Beantwortung von Fragen, die als zu beantwortend markiert sind, möglich",
    questionsRequiredExplanationNo: "Bedeutung: Hinweis beim Seitenwechsel, wenn zu beantwortende Fragen nicht beantwortet sind; Lektion kann abgeschickt werden",
    questionsRequiredExplanationYes: "Bedeutung: Warnung beim Seitenwechsel, wenn zu beantwortende Fragen nicht beantwortet sind; Lektion kann nicht abgeschickt werden",
    errorPreviewNoElements: "Die Lektion hat keine Elemente.",
    errorPreviewFirstElementPage: "Das erste Element der Lektion kann kein Seitenumbruch sein.",
    deletePage: "Ganze Seite löschen",
    allConditions: "Alle konditionalen Inhalte anzeigen",
    allConditionsTip: "Alle Bedingungen gelten als erfüllt, unabhängig von den Antworten der Fragen",
    ignorePageConditions: "Seitenbedingungen nicht auswerten",
    ignorePageConditionsTip: "Alle Seiten werden angezeigt, unabhängig davon, ob die Seitenbedingung erfüllt ist",
    questionsRequiredCheck: "Hinweise zu erforderlichen Fragen",
    questionsRequiredTip: "An: Beim Seitenwechsel und Absenden der Lektion werden die Hinweise so angezeigt wie in der Patient:innen App; Aus: zum leichteren Ansehen der Inhalte",
    warning: "Achtung",
    warningUnansweredRequiredQuestionsNextPage: "Es befinden sich unbeantwortete, jedoch benötigte Fragen auf dieser Seite. Trotzdem weitermachen?",
    warningUnansweredRequiredQuestionsSubmit: "Es befinden sich unbeantwortete, jedoch benötigte Fragen in der Lektion. Nachdem diese beantwortet wurden, kann die Lektion abgeschlossen werden.",
    warningUnansweredNonRequiredQuestionsNextPage: "Es befinden sich unbeantwortete Fragen auf dieser Seite. Trotzdem weitermachen?",
    warningUnansweredNonRequiredQuestionsSubmit: "Es befinden sich unbeantwortete Fragen in der Lektion. Trotzdem abschließen?",
    continue: "Weiter",
    removedOldConditionalPage: "{counter} Seitenumbruch/-umbrüche in Blöcken wurde(n) entfernt (auf der/den Seite(n) {pages}). Bitte hierfür stattdessen die Bedingung des Seitenelements nutzen.",
    selectPreviewSize: "Bildschirmgröße auswählen..",
    selectPreviewSizeDesktop: "PC / Tablet quer",
    selectPreviewSizeMobile: "Mobile",
    selectPreviewSizeTablet: "Tablet hoch",
  },
  elementsBlockTranslation: {
    typeOfBlock: "Typ des Blocks",
    selectTypeOfBlock: "Typ des Blocks auswählen",
    typeConditional: "Konditionaler Block",
    typeDetails: "Aufklappbarer Block",
    typeBoth: "Aufklappbarer konditionaler Block",
    textForDetails: "Text des aufklappbaren Blocks",
    textForDetailsTranslation: "Übersetzung des Texts des aufklappbaren Blocks",
    beginBlock: "Anfang des Blocks",
    blockFor: "Block für",
    blockForWithDetails: "Aufklappbarer Block für",
    condition: "Bedingung zum Anzeigen des Blocks",
    selectLabel: "Label auswählen..",
    answeredWith: "beantwortet mit",
    answeredAny: "beantwortet mit beliebigem Wert",
    selectAnswer: "Antwort auswählen..",
    updateLabellistManually: "Labelliste manuell aktualisieren",
    endBlock: "Ende des Blocks",
    errorLabel: "Bitte ein Label aus der Liste auswählen. Wenn die Labelliste leer ist, muss zuerst eine Frage erstellt werden.",
    errorTypeEmpty: "Blocktyp",
    errorValueEmpty: "Antwort der Frage",
    errorOperationEmpty: "Operation der Frage",
    errorSecondValueEmpty: "Zweite Antwort der Frage",
    errorValueSecondValue: "Der erste Wert muss kleiner sein als der zweite.",
    errortextDetailsEmpty: "Text des aufklappbaren Blocks",
    complexCondition: "Komplexe Bedingung",
    simpleCondition: "Einfache Bedingung",
    defineCondition: "Bedingung festlegen",
    changeCondition: "Bedingung anpassen",
    noQuestions: "Wenn die Labelliste leer ist, muss zuerst eine Frage erstellt werden.",
    errorComplexCondition: "Die komplexe Bedingung ist unvollständig und muss überarbeitet werden",
    conditionDescription: "Beschreibung der Bedingung",
    repetition: "Wiederholung",
    repetitionInstruction: "Zahl, wie oft der Inhalt des Block angezeigt wird",
    constant: "konstant",
    dependingOnQuestion: "Antwort der Slider-Frage",
    errorRepetitionMin: "Zahl der Wiederholungen (größer 0)",
    errorRepetitionLabel: "Frage-Referenz für Wiederholung",
    noneBlock: "Block",
    repetitionContent: "Inhalt",
    repetitionDisplayed: "mal angezeigt",
    repetitionQuestionContent: "Inhalt so oft angezeigt wie Antwort der Frage",
    none: "Nur Wiederholung",
    typeNone: "Wiederholungsblock",
    dateFormat: "JJJJ-MM-TT",
    timeHours: "hh",
    timeMinutes: "mm",
    placeholderTime: "hh:mm",
    placeholderDate: "tt.mm.jjjj",
    sum: "Summe",
  },
  elementsHeadlineTranslation: {
    headline: "Überschrift",
    headlineTranslation: "Übersetzung der Überschrift",
    selectHeadlineFontsize: "Größe der Überschrift auswählen..",
    selectHeadlineFont: "Font der Überschrift auswählen..",
    fontNone: "Kein",
    headlineType0: "Keine",
    headlineType1: "Überschrift 1",
    headlineType2: "Überschrift 2",
    headlineType3: "Überschrift 3",
    headlineType4: "Überschrift 4",
    headlineType5: "Überschrift 5",
    headlineType6: "Überschrift 6",
    headlineFontsize: "Größe der Überschrift",
    selectHeadlineColor: "Farbe der Überschrift",
    headlineFont: "Font der Überschrift",
  },
  elementsPageTranslation: {
    pageConfig: "Konfiguration der Seite",
    selectPageColor: "Hintergrund der Seite",
    defaultColor: "Standard (Lektionsdetails)",
    addCondition: "Bedingung hinzufügen",
    removeCondition: "Bedingung entfernen",
    condition: "Bedingung zum Anzeigen der Seite",
    conditional: "konditional",
    deletePageBreak: "Seitenumbruch löschen",
    progressbarText: "Text für Fortschrittsbalken",
    progressbarTextTranslation: "Übersetzung des Texts für Fortschrittsbalken",
  },
  elementsLineTranslation: {
    line: "Trennlinie",
  },
  elementsSpaceTranslation: {
    space: "Abstand",
    size: "Größe",
    selectSize: "Größe auswählen..",
    errorSize: "Größe auswählen"
  },
  elementsMediaTranslation: {
    media: "Medien",
    title: "Titel",
    titleTranslation: "Übersetzung des Titels",
    file: "Datei",
    sameFile: "Gleiche Datei wie bei der Übersetzung verwenden",
    upload: "Hochladen",
    selectMedia: "Medium auswählen",
    selectPicture: "Bild auswählen",
    deletePicture: "Bild löschen",
    selectType: "Typ auswählen",
    all: "Alle Typen",
    image: "Bild",
    video: "Video",
    audio: "Audio",
    application: "Datei",
    description: "Beschreibung",
    descriptionTranslation: "Übersetzung der Beschreibung",
    errorSelectMedia: "Medium auswählen",
    uploadFileError: "Datei hochladen",
    uploadFileTranslationError: "Datei hochladen oder die Datei der Übersetzung verwenden.",
    uploadStudyMediaLoad: "Die Medien werden hochgeladen..",
    uploadStudyMediaSuccess: "Die Medien wurden hochgeladen.",
    noMedia: "Keine Medien vorhanden",
    noMediaTooltip: "Medien können über das Medienauswahlfeld oben hochladen werden.",
    size: "Breite (%)",
    banner: "Banner",
    isAudio: "Nur Audio",
    sizeValidValue: "Breite (gültiger Wert zw. 0 und 100)",
    fileNotSupported: "Der Typ der ausgewählten Datei wird nicht unterstützt. Folgende Dateitypen werden unterstützt: jpeg, jpg, png, gif, svg, wav, m4a, mp3, mp4, pdf, doc, docx, odt, ppt, pptx, vtt.",
    selectMediaDelete: "Medien auswählen",
    selectMediaAbort: "Medienauswahl aufheben",
    deleteMedia: "Medien löschen",
    deleteMediaLoad: "Die Medien werden gelöscht..",
    deleteMediaSuccess: "Die Medien wurden gelöscht..",
    deleteWarning: 'Sollen die ausgewählten Medien wirklich gelöscht werden?',
    deleteWarningText: "Die Medien werden auch aus Interventionen, Tagebüchern und Lektionen der Gruppe entfernt.",
    favorite: "Favorit",
    notFavorite: "Kein Favorit",
    favoriteNoFavorite: "Favorit & kein Favorit",
    updateMediaAttributesLoad: "Die Änderungen werden gespeichert..",
    updateMediaAttributesSuccess: "Die Änderungen wurden gespeichert.",
    errorTooLarge: "Eine oder mehrere Dateien übersteigen das Limit von 150 MB.",
    recommendedMaxSize: "Empfohlene maximale Dateigröße: 50 MB",
    supportedFileTypes: "Unterstütze Dateitypen: jpeg, jpg, png, gif, svg, wav, m4a, mp3, mp4, pdf, doc, docx, odt, ppt, pptx, vtt",
    videoMobileRecommendation: "Für Videos auf Mobilgeräten wird empfohlen",
    videoMobileSpec: "MP4-Videos mit H.264 (AVC) Videocodec, AAC Audiocodec, 60Hz Bildwiederholrate",
    videoMobileRes1080: "Auflösung 1080p (1920x1080 Pixel) für Videos die im Vollbildmodus angesehen werden sollen",
    videoMobileRes480: "Auflösung 480p (854x480 Pixel) für Videos die nicht im Vollbildmodus angesehen werden sollen",
    videoMobileH265: "Für höhere Auflösungen ist Videocodec H.265 (HEVC) eine Alternative",
    subtitles: "Untertitel",
    selectSubtitles: "Untertitel auswählen",
    noSelectSubtitles: "Keine Untertitel ausgewählt",
    deleteSubtitles: "Untertitel löschen",
  },
  elementsTextTranslation: {
    text: "Text",
    textType: "Art des Texts auswählen..",
    textTypeDesc: "Art des Texts",
    none: "Keine",
    important: "Wichtig",
    tip: "Tipp",
    info: "Info",
    success: "Erfolg",
    highlight: "Hervorhebung",
    errorFillIn: "Folgende Felder ausfüllen: ",
    errorFillInOne: "Mindestens eins der folgende Felder ausfüllen: ",
    errorOneTranslation: "in mindestens einer Sprache",
    errorOtherPictures: "Externe Bilder entfernen und unter Medien verwalten hochladen",
    font: "Schriftart auswählen",
    header: "Überschrift auswählen",
    bold: "Fett",
    italic: "Kursiv",
    underline: "Unterstrichen",
    strike: "Durchgestrichen",
    align: "Linksbündig",
    alignCenter: "Zentriert",
    alignRight: "Rechtsbündig",
    alignJustify: "Blocksatz",
    blockquote: "Zitat",
    listOrdered: "Nummerierte Liste",
    listBullet: "Unnummerierte Liste",
    scriptSub: "Tiefgestellt",
    scriptSuper: "Hochgestellt",
    indentMinus: "Ausrücken",
    indentPlus: "Einrücken",
    color: "Schriftfarbe",
    background: "Hintergrundfarbe",
    link: "Link einfügen",
    image: "Bild einfügen",
    clean: "Formatierung entfernen",
    expand: "Vergrößern/Verkleinern",
    reference: "Referenz",
  },
  elementsTableTranslation: {
    table: "Tabelle",
    header: "Überschriftzeile",
    row: "Zeile",
    column: "Spalte",
    expand: "Vergrößern",
  },
  elementsQuestionTranslation: {
    yesNoSwitch: "Frage mit Ja-Nein-Antwort",
    singleChoice: "Frage mit genau einer Antwort",
    multipleChoice: "Frage mit ggf. mehreren Antworten",
    slider: "Frage mit Slider-Antwort",
    textDate: "Frage mit Datums-Antwort",
    textTime: "Frage mit Zeit-Antwort",
    textDateTime: "Frage mit Datums- und Zeit-Antwort",
    textString: "Frage mit kurzer Text-Antwort",
    textArea: "Frage mit langer Text-Antwort",
    invalidQuestionTable: "Antwortmöglichkeiten und Fragen",
    questionTable: "Fragentabelle",
    question: "Frage",
    questions: "Fragen",
    questionType: "Fragetyp",
    selectQuestionType: "Art der Frage auswählen..",
    questionLabel: "Fragelabel",
    reference: "Referenz für konditionale Inhalte",
    questionTranslation: "Übersetzung der Frage",
    answer: "Antwort",
    answerExample: "Antwortbeispiel",
    answerOptions: "Antwortmöglichkeiten",
    answerOption: "Antwortmöglichkeit",
    answerLabel: "Bezeichner",
    answerTranslation: "Übersetzung der Antwort",
    addAnswerOption: "Weitere Antwortmöglichkeit hinzufügen",
    addQuestion: "Weitere Frage hinzufügen",
    valueMin: "Minimaler Wert",
    valueMax: "Maximaler Wert",
    labelMinMax: "Bezeichnung (max. 40 Zeichen)",
    labelMinMaxTranslation: "Übersetzung der Bezeichnung (max. 40 Zeichen)",
    step: "Schrittweite",
    questionRequired: "Beantwortung erforderlich?",
    answerfield: "Antwortfeld",
    singleMultipleChoiceError: "Eine Single bzw. Multiple Choice Frage muss mindestens zwei Antwortmöglichkeiten haben.",
    tableAnswersError: "Eine Fragentabelle muss mindestens zwei Antwortmöglichkeiten haben.",
    tableQuestionsError: "Eine Fragentabelle muss mindestens zwei Fragen haben.",
    errorAnswerValueEmpty: "Bezeichner der Antwortmöglichkeit",
    errorCurlyBraceLabel: "Symbole { und } sind in diesem Feld nicht erlaubt.",
    errorDuplicateAnswerLabel: "eindeutige Bezeichner für Antwortmöglichkeiten",
    errorSliderMinTextValueEmpty: "Bezeichner des minimalen Werts",
    errorSliderMaxTextValueEmpty: "Bezeichner des maximalen Werts",
    errorSliderTooManyValues: "Es darf höchstens 101 Werte geben. Schrittweite bzw. minimalen oder maximalen Wert anpassen.",
    errorDuplicateLabel: "Dieses Frage-Label wird bereits bei einer anderen Frage verwendet.",
    errorRepetition: "Diese Slider-Frage kann nicht wiederholt werden, da abhängig von dieser eine Wiederholung definiert wurde. Die Wiederholung des äußeren Blocks muss auf 1 gesetzt oder die Wiederholung, die diese Slider-Frage verwendet, muss angepasst werden.",
    generateQuestionLabel: "Generieren",
    removeAnswer: "Antwort löschen",
    removeQuestion: "Frage löschen",
    dateFormat: "l, j. F Y",
    dateFormatCondition: "j. F Y",
    timeFormat: "H:i \\U\\h\\r",
    ampm: "false",
    errorSingleMultiple: "Die Anzahl der Antworten und Labels stimmt nicht überein",
    startValue: "Startwert",
    startValueNotIncluded: "Der Startwert liegt nicht im Intervall.",
    minGreaterMax: "Der minimale Wert ist größer als der maximale Wert.",
    minIsMax: "Der minimale Wert und der maximale Wert müssen verschieden sein.",
    selectDate: "Bitte wählen",
    time: "Uhr",
  },
  appTranslation: {
    topButton: "Zurück nach oben",
  },
  resetPasswordTranslation: {
    to: "Zum",
    resetPasswordInstructions: "E-Mail Adresse eingeben um einen Link zur Passwort-Änderung zu erhalten.",
    verifyEmail: "E-Mail Adresse verifizieren",
    resetPasswordLoad: "Eine E-Mail zum Ändern des Passworts wird gesendet..",
    resetPasswordSuccess: "Es wurde eine Passwort-Wiederherstellungs-Mail an die folgende E-Mail-Adresse gesendet, falls die E-Mail in dieser Schreibweise im System vorhanden ist: ",
    resetPasswordSuccessHint: "Falls die E-Mail nicht ankam, bitte die Schreibweise überprüfen.",
    send: "Senden",
  },
  permissionsTranslation: {
    permissionsInstructions: "Hier werden die Zugriffsrechte für die Gruppe festgelegt.",
    ownPermissionsNotEditable: "Die eigenen Berechtigungen sind nicht bearbeitbar.",
    permissionsNotEditable: "Keine Berechtigung zur Änderung",
    collab: "Benutzer:innen mit Berechtigungen",
    nonCollab: "Alle Benutzer:innen",
    owner: "Owner",
    editPermission: "Bearbeitungsrechte",
    copyPermission: "Kopierrechte",
    noPermission: "Kein Zugriff",
    eCoach: "E-Coach",
    eCoachManager: "E-Coach Manager",
    updateStudyPermissionsLoad: "Die Berechtigungen werden aktualisiert..",
    updateStudyPermissionsSuccess: "Die Berechtigungen wurden aktualisiert.",
    unsavedChangesPermissions: "Die Berechtigungen enthalten ungesicherte Änderungen.",
    leaveSiteWarning: "Ansicht wirklich verlassen?",
    chooseUsers: "Ansicht auswählen..",
    noUsers: "Keine Benutzer:innen vorhanden.",
    permissionOwner: "Alle Funktionen in der Gruppe",
    permissionEditPermission: "Eingeschränkte Funktionen (keine Berechtigungen ändern, Inhalte löschen oder veröffentlichen)",
    permissionCopyPermission: "Inhalte ansehen und in andere Gruppe kopieren, Inhalte nicht bearbeitbar",
    permissionNoPermission: "Gruppe und Interventionen sichtbar, keine Details einsehbar",
  },
  httpHelperTranslation: {
    errorCode2: "Keine Berechtigung.",
    errorCode3: "Falscher Request Typ.",
    errorCode4: "Die Entität wurde nicht gefunden.",
    errorCode5: "Die Entität existiert bereits.",
    errorCode6: "Die Entität ist nicht aktiv.",
    errorCode7: "Die Entitäten wurden nicht gefunden.",
    errorCode10: "Dieser Pfad ist nicht erlaubt.",
    errorCode11: "Die Anmeldedaten sind ungültig.",
    errorCode12: "Bitte erneut einloggen.", //Auth-Token Fehler.
    errorCode13: "Bitte erneut einloggen.",//Der Auth-Token ist abgelaufen.
    errorCode14: "Bitte erneut einloggen.",//Der Auth-Token ist blacklisted.
    errorCode20: "Bitte erneut einloggen.",//Der Token fehlt.
    errorCode21: "Bitte erneut einloggen.",//Der Token ist abgelaufen.
    errorCode22: "Der Reset Token fehlt.",
    errorCode23: "Der Reset Token ist abgelaufen.",
    errorCode24: "Der Verification Token fehlt.",
    errorCode25: "Der Verification Token ist abgelaufen.",
    errorCode30: "Die E-Mail Adresse wurde noch nicht verifiziert.",
    errorCode31: "Die E-Mail Adresse wurde bereits bestätigt.",
    errorCode32: "Die E-Mail Adresse oder der Benutzername ist bereits vorhanden.",
    errorCode40: "Das Medien Format wird nicht unterstützt.",
    errorCode41: "Base64 Encoding Fehler.",
    errorCode42: "Das Medium wurde nicht gefunden.",
    errorCode82: "Die Lektion ist aktiv.",
    errorCode83: "Der Lektion fehlen Daten.",
    errorCode84: "Die Anzahl der Blöcke der Lektion ist nicht gleich.",
    errorCode85: "Die Intervention ist aktiv.",
    httpErrorCode500: "Internal Server Error.",
    httpErrorCode503: "Service Unavailable.",
    httpErrorCode504: "Gateway Timeout.",
    error: "Ein Fehler ist aufgetreten: ",
    registerErrorEmailInvalid: "Die E-Mail Adresse ist ungültig.",
    registerErrorEmailDuplicate: "Es ist bereits ein Account zu dieser E-Mail Adresse vorhanden.",
    errorLoginAgain: "Bitte erneut einloggen.",
    errorInvalidEmail: "Bitte eine gültige E-Mail Adresse eingeben.",
    wrongPassword: "Das Passwort ist nicht korrekt",
    tooManyLogins: "Zu viele Loginversuche. Vor erneutem Einloggen bitte 1 Minute warten.",
    usernameAlreadyExists: "Der Benutzername ist bereits vergeben. Bitte einen anderen wählen.",
    passwordDataLeak: "Das gewählte Passwort wurde bei einem Daten Leak gefunden. Bitte ein anderes Passwort wählen.",
    configDiaryNotIncluded: "Ein für eine Bedingung ausgewähltes Tagebuch ist nicht Teil der Intervention. Bitte das Tagebuch für die Intervention oder eine der Lektionen hinzufügen oder ein anderes Tagebuch wählen.",
    saveElementTranslationError: "Die Übersetzungen der Elemente können aufgrund eines Konflikts nicht gespeichert werden. Die Elemente müssen neu geladen werden.",
    createElementsLockError: "Nur die Übersetzungen der Elemente dürfen verändert werden.",
    changeStructureLockAccessError: 'Nur ein Benutzer mit "Onwer" Berechtigungen kann das Schloss verändern.',
  },
  conditionEditorTranslation: {
    addNewItems: "Elemente mit Drag and Drop hinzufügen",
    condition: "Bedingung",
    clipboard: "Zwischenablage",
    testCondition: "Bedingung testen",
    testConditionDescription: "Hier kann die Bedingung durch Auswahl der Antworten der einzelnen Fragen getestet werden. Dabei können Fragen auch unbeantwortet bleiben.",
    test: "Testen",
    hideTest: "Test ausblenden",
    errorCondition: "Zum Testen muss die Bedingung vollständig sein. Dazu in die gestrichelten Felder weitere Bedingungen bzw. Blöcke einfügen und für die Bedingungen jeweils Label, Antwort und ggf. Operation auswählen.",
    questionAnswered: "Frage beantwortet",
    with: "mit",
    apply: "Übernehmen",
    dragAndDropArea: "Hier können per Drag and Drop Frage-, UND-, ODER- und NICHT-Blöcke hinzugefügt werden.",
    clipboardArea: "Hierhin können Bedingungen bzw. komplette Blöcke bewegt werden um diese vorerst zwischenzuspeichern.",
    errorConditionNotComplete: "Die Bedingung ist nicht vollständig. Bitte in die gestrichelten Felder weitere Bedingungen bzw. Blöcke einfügen und für die Bedingungen jeweils Label, Antwort und ggf. Operation auswählen.",
    tooltipQuestion: 'Hiermit kann eine einzelne Bedingung festgelegt werden.',
    tooltipAnd: 'Hiermit können zwei oder mehr Bedingungen oder Blöcke mit "UND" verknüpft werden. Ein UND-Block ist erfüllt, wenn ALLE Bedingungen bzw. Blöcke in diesem zutreffen.',
    tooltipOr: 'Hiermit können zwei oder mehr Bedingungen oder Blöcke mit "ODER" verknüpft werden. Ein ODER-Block ist erfüllt, wenn MINDESTENS EINE der Bedingungen bzw. Blöcke in diesem zutrifft.',
    tooltipNot: 'Ein NICHT-Block verneint die enthaltene Bedingung bzw. den enthaltenen Block.',
    and: "UND",
    or: "ODER",
    not: "NICHT",
    selectOperation: "Operation auswählen",
    between: "zwischen",
    betweenAnd: "und",
    nextField: "weiteres Feld",
    conditionString: "Die Bedingung ist erfüllt, wenn: ",
    questionString: "die Frage",
    withString: "mit dem Wert",
    withAnyString: "mit irgendeinem Wert beantwortet wurde",
    operationString: "mit einem Wert",
    answeredString: "beantwortet wurde",
    less: "kleiner",
    lessEqual: "kleiner gleich",
    greater: "größer",
    greaterEqual: "größer gleich",
    equals: "gleich",
    notEquals: "ungleich",
    unsavedChangesCondition: "Die Bedingung enthält ungesicherte Änderungen.",
    unsavedChangesConditionLessonElements: "Die Lektionselemente und die Bedingung enthalten ungesicherte Änderungen.",
    codeView: "Code-Editor",
  },
  codeViewTranslation: {
    selectSnippet: "In den Code Editor klicken oder ein Textstück markieren. Das Codestück wird dann an dieser Position eingefügt",
    insert: "Einfügen",
    values: "Values für das oben ausgewählte QuestionLabel (auch für SecondValue verwendbar)",
    selectValue: "Value auswählen",
    showExplanation: "Erklärung anzeigen",
    correctErrors: "Fehler",
    clickOrSelect: "Zuerst in den Code Editor klicken oder ein Textstück markieren und dann ein Codestück einfügen.",
    questionLabels: "QuestionLabels",
    operations: "Operations",
    leafTypes: "LeafTypes",
    leafTypeToQuestionLabel: "LeafType für QuestionLabel",
    hasToBe: "muss sein:",
    valueToQuestionLabel: "Value für QuestionLabel",
    hasToBeOneOf: "muss einer der folgenden sein:",
    secondValueToQuestionLabel: "SecondValue für QuestionLabel",
    hasToBeValidDate: "muss ein gültiges Datum im Format JJJJ-MM-TT sein",
    questionLeaf: "Frage",
    leafType: "Typ der Frage",
    question: "Ja-Nein/Single/Multiple",
    slider: "Slider",
    date: "Datum",
    text: "Text",
    none: "Keine",
    addAtLeastTwo: "hier mindestens zwei UND-/ ODER-/ NICHT-/ Frage-Blöcke einfügen",
    separated: "getrennt durch , ",
    addExactlyOne: "hier genau einen UND-/ ODER-/ NICHT-/ Frage-Block einfügen",
    oneOfLeafType: "einer der folgenden:",
    label: "Label",
    ifNotOperationAnswered: 'nur wenn operation nicht "answered"',
    ifQuestion: 'wenn Question: true/false/"Label"',
    ifSlider: "wenn Slider: Zahl",
    ifDate: 'wenn Date: Zeichenfolge wie "2019-10-02"',
    ifBetween: 'nur bei Operation "between"',
    codeSnippets: "Codestücke",
    editorView: "Editor-Ansicht",
    questionLabel: "Das Label",
    isNotValid: "ist nicht gültig",
    errorSave: "Beim Speichern sind Fehler aufgetreten (siehe unten). Bitte Fehler korrigieren und erneut speichern.",
    errorSwitchCondition: "Es sind Fehler aufgetreten (siehe unten). Bitte Fehler korrigieren um zur Editor-Ansicht zu wechseln.",
    errorSnippet: "Es sind Fehler aufgetreten (siehe unten).",
    conditionEmpty: "Die Bedingung darf nicht leer sein.",
    inLine: "in line",
    valueInstruction: "QuestionLabel auswählen, damit die zugehörigen Values angezeigt werden.",
    ifNotQuestion: "für Slider oder Date",
    forAll: "für alle",
    forQuestion: "für Question",
  },
  referenceTranslation: {
    insertReference: "Referenz einfügen",
    insert: "Einfügen",
    referenceFor: "Referenz für Frage",
    answerOf: "Antwort von",
  },
  adminUsersTranslation: {
    manageUsers: "Benutzer:innen verwalten",
    allUsers: "Alle Benutzer:innen",
    deletionRequests: "Account-Löschungsanfragen",
    edit: "Bearbeiten",
    errorIncompleteForm: 'Benutzername, Vorname und Nachname müssen angegeben werden.',
    infoDeletedUserNames: 'Vorname und Nachname von Benutzern ohne Rollen werden gelöscht.',
    infoNoChangesMade: 'Es wurden keine Veränderungen vorgenommen.',
    errorEmptyRoles: 'Markiere mindestens 1 Rolle.',
    changeRoles: 'Rollen bearbeiten für Benutzer:in "{email}"',
    changePatientRoles: 'Benutzer:in "{email}" bearbeiten',
    unsavedChangesRoles: "Die Einstellungen enthalten ungesicherte Änderungen. Änderungen speichern oder erneut Abbrechen klicken.",
    saveRolesLoad: "Die Rollen werden gespeichert..",
    saveRolesSuccess: "Die Rollen wurden gespeichert.",
    savePatientRolesLoad: "Die Rollen und Benutzereinstellungen werden gespeichert..",
    savePatientRolesSuccess: "Die Rollen und Benutzereinstellungen wurden gespeichert.",
    verified: "E-Mail Adresse bestätigt",
    notVerified: "E-Mail Adresse noch nicht bestätigt",
    verifiedNotVerified: "E-Mail bestätigt & nicht bestätigt",
    createAccount: "Neuen Account anlegen",
    role: "Rolle",
    patient: "Patient:in",
    editor: "Editor:in",
    ecoach: "E-Coach",
    admin: "Admin",
    allRoles: "Alle Rollen",
    username: "Benutzername",
    firstname: "Vorname",
    lastname: "Nachname",
    errorFillIn: "Folgende Felder ausfüllen: ",
    createAccountLoad: "Der Account wird erstellt..",
    createAccountSuccess: "Der Account wurde erstellt. Eine Verifizierungs-E-Mail wurde versendet.",
    resendVerification: "Verifizierungs-E-Mail erneut senden",
    resend: "Senden",
    resendVerificationLoad: "Verifizierungs-E-Mail wird gesendet..",
    resendVerificationSuccess: "Verifizierungs-E-Mail wurde gesendet.",
    adminFunctions: "Admin Funktionen",
    deleteWarning: 'Soll der/die Benutzer:in "{email}" wirklich gelöscht werden?',
    deleteUserLoad: "Benutzer:in wird gelöscht..",
    deleteUserSuccess: "Benutzer:in wurde gelöscht.",
    noDeletionRequests: "Keine Account-Löschungsanfragen vorhanden",
    userDetails: "Benutzer:in",
    studiesOfUser: "Studien des/der Benutzers:in",
    eCoachManager: "eCoachManager:innen",
    data: "Daten",
    deleteAll: "Komplett löschen",
    pseudonymize: "Pseudonymisieren",
    dataDeleted: "Die Benutzerdaten werden komplett gelöscht.",
    dataPseudonymized: "Die Benutzerdaten werden pseudonymisiert.",
    userHasNoStudies: "Benutzer:in ist in keiner Studie",
    acceptDeletion: "Löschungsanfrage akzeptieren",
    declineDeletion: "Löschungsanfrage ablehnen",
    acceptAccountDeletionLoad: "Löschungsanfrage wird akzeptiert..",
    acceptAccountDeletionSuccess: "Löschungsanfrage wurde akzeptiert. Der/die Benutzer:in erhält eine Bestätigungsmail.",
    declineWarning: 'Soll die Anfrage für den/die Benutzer:in "{email}" wirklich abgelehnt werden?',
    declineText: "Der Account des/der Benutzer:in wird nicht gelöscht.",
    decline: "Ablehnen",
    declineAccountDeletionLoad: "Löschungsanfrage wird abgelehnt..",
    declineAccountDeletionSuccess: "Löschungsanfrage wurde abgelehnt. Der/die Benutzer:in erhält eine E-Mail bzgl. Ablehnung der Anfrage.",
    acceptDecline: "Anfrage annehmen/ ablehnen",
  },
  preferencesTranslation: {
    preferences: "Einstellungen",
    changeAppLanguage: "Sprache der Seite ändern",
    appLanguageText: "Die Inhaltssprache kann innerhalb der Gruppen, Interventionen, Tagebücher und Lektionen angepasst werden.",
    profile: "Benutzerprofil",
    updateProfileLoad: "Benutzerprofil wird gespeichert..",
    updateProfileSuccess: "Benutzerprofil wurde gespeichert.",
    notEditable: "nicht veränderbar",
    roles: "Rollen",
    changePW: "Passwort ändern",
    passwordOld: "Altes Passwort",
    passwordNew: "Neues Passwort",
    passwordNewConfirm: "Neues Passwort wiederholen",
    change: "Ändern",
    changePWLoad: "Das Passwort wird geändert..",
    changePWSuccess: "Das Passwort wurde geändert.",
    changePWError: "Die Passwörter stimmen nicht überein.",
  },
  diaryTranslation: {
    diaryDetails: "Tagebuchdetails",
    updateDiaryDetailsLoad: "Die Tagebuchdetails werden gespeichert..",
    updateDiaryDetailsSuccess: "Die Tagebuchdetails wurden gespeichert.",
    languagesDiary: "Sprachen des Tagebuchs",
    participants: "Teilnehmer:innen des Tagebuchs",
    diaryElements: "Elemente des Tagebuchs",
    diary: "Tagebuch",
    deleteWarning: 'Soll das Tagebuch "{name}" wirklich gelöscht werden?',
    deleteWarningUsed: "Das Tagebuch wird in Interventionen oder Lektionen der Gruppe verwendet.",
    deleteDiaryLoad: "Das Tagebuch wird gelöscht..",
    deleteDiarySuccess: "Das Tagebuch wurde gelöscht.",
    newDiary: 'Neues Tagebuch erstellen in Gruppe "{name}"',
    createDiaryLoad: "Das Tagebuch wird erstellt..",
    createDiarySuccess: "Das Tagebuch wurde erstellt.",
    copyDiary: 'Tagebuch "{name}" kopieren',
    copyDiaryLoad: "Das Tagebuch wird kopiert..",
    copyDiarySuccess: "Das Tagebuch wurde kopiert.",
    copyInstruction: "Zum Kopieren des Tagebuchs bitte eine Gruppe auswählen.",
    exportDiaryLoad: "Das Tagebuch wird exportiert..",
    exportDiarySuccess: "Das Tagebuch wurde exportiert.",
    importDiary: 'Tagebuch importieren',
    importDiaryHeader: 'Tagebuch in Gruppe "{name}" importieren',
    selectImport: "JSON Datei mit exportiertem Tagebuch",
    importDiaryLoad: "Das Tagebuch wird importiert..",
    importDiarySuccess: "Das Tagebuch wurde importiert.",
    publishDiarySuccess: "Das Tagebuch wurde in die Studie der E-Coach Plattform veröffentlicht.",
    diaryNotComplete: "Das Tagebuch wurde nicht korrekt erstellt. Bitte löschen und ein neues erstellen.",
    diaryInstruction: "Tagebuch zuweisen",
    diaryInstructionTooltip: "Tagebuch zu Intervention/Lektion zuordnen über Intervention > zugehörige Lektionen > Konfiguration anpassen",
  },
  adminAnnouncementsTranslation: {
    adminAnnouncements: "Ankündigungen verwalten",
    createAnnouncement: "Ankündigung erstellen",
    editAnnouncement: "Ankündigung bearbeiten",
    content: "Inhalt",
    title: "Titel DE",
    message: "Nachricht DE",
    messageWithChars: "Nachricht DE (max. 255 Zeichen)",
    titleTranslation: "Titel EN",
    messageTranslation: "Nachricht EN",
    messageWithCharsTranslation: "Nachricht EN (max. 255 Zeichen)",
    type: "Typ",
    success: "Erfolg",
    info: "Info",
    warning: "Warnung",
    warnings: "Warnungen",
    error: "Fehler",
    errors: "Fehler",
    creator: "Ersteller",
    clients: "Plattformen",
    cms: "CMS",
    ecoach: "eCoach Plattform",
    app: "Patient:innen App",
    date: "Gültigkeit",
    startDate: "Startdatum",
    endDate: "Enddatum",
    errorFillIn: "Folgende Felder ausfüllen: ",
    createAnnouncementLoad: "Die Ankündigung wird erstellt..",
    createAnnouncementSuccess: "Die Ankündigung wurde erstellt.",
    updateAnnouncementLoad: "Die Ankündigung wird gespeichert..",
    updateAnnouncementSuccess: "Die Ankündigung wurde gespeichert.",
    dateTimeFormat: "j. F Y, H:i \\U\\h\\r",
    deleteWarning: 'Soll die Ankündigung "{name}" wirklich gelöscht werden?',
    deleteAnnouncementLoad: "Die Ankündigung wird gelöscht..",
    deleteAnnouncementSuccess: "Die Ankündigung wurde gelöscht.",
    selectType: "Typ auswählen..",
    selectClient: "Plattform auswählen..",
    shown: "Nur aktuelle",
    noAnnouncements: "Keine Ankündigungen vorhanden",
    unsavedChanges: "Die Ankündigung enthält ungesicherte Änderungen. Änderungen speichern oder erneut Abbrechen klicken.",
    allTypes: "Alle Typen",
    allPlatforms: "Alle Plattformen",
  }
};
